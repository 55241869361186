import React from "react";
import { Editor } from "@tinymce/tinymce-react";

interface Props {
  value: string;
  setValue?: (content: string) => void;
  name?: string;
  formik?: any
}

const TinyMCEEditor = ({ value, setValue, name, formik }: Props) => {

  return (
    <Editor
    tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}

      value={value}
      init={{
        menubar: false,
        plugins: "link code",
        toolbar:
          "undo redo | pastetext removeformat | link | blocks bold italic underline strikethrough superscript subscript | align bullist numlist",
      }}
      id={name}
      onEditorChange={(e: any) => {formik.setFieldValue(name, e)}
    }
    />
  );
};

export default TinyMCEEditor;
