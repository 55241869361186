import React, { useState } from "react";
import { Categories, Daum, Name } from "./models";
import { useAppDispatch } from "src/store";

interface Props {
  onClose: () => void;
  is_edit?: boolean;
  data?: any;
  id?: number;
  addFunction?: (data: any) => any;
  editFunction?: (data: any) => any;
  titleCG: string;
  titleEN: string;
  categories?: Categories | null;
}

function AddEditModal({
  onClose,
  data,
  id,
  is_edit,
  addFunction,
  editFunction,
  titleCG,
  titleEN,
  categories,
}: Props) {
  const [isChecked, setIsChecked] = useState(data && data.parent_id ? true : false);
  const [parentCategory, setParentCategory] = useState(data && data.parent_id ? data.parent_id : "");
  const [nazivCNR, setNazivCNRRef] = useState(
    data && is_edit && data.name.cg ? data.name.cg : ""
  );
  const [nazivENG, setNazivENGRef] = useState(
    data && is_edit && data.name.en ? data.name.en : ""
  );
  const [language, setLanguage] = useState("cg");
  const [requiredCG, setRequiredCG] = useState(false);
  const [requiredEN, setRequiredEN] = useState(false);

  const dispatch = useAppDispatch();
  async function handleSuccess(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (nazivCNR === "") {
      setRequiredCG(true);
      setLanguage("cg");
      return;
    }

    if (nazivENG === "") {
      setRequiredEN(true);
      setLanguage("en");
      return;
    }
    let data:any = {
      name: {
        cg: nazivCNR,
        en: nazivENG,
      },
    };

    if(isChecked && parentCategory !== ""){
      data = {...data, parent_id: parseInt(parentCategory)}
    }

    if (is_edit) {
      if (editFunction) {
        dispatch(editFunction({ ...data, id }));
      }
    } else {
      if (addFunction) {
        dispatch(addFunction(data));
      }
    }

    onClose();
  }

  return (
    <>
      <div>
        <div className="card-header mb-3">
          <ul className="nav nav-tabs card-header-tabs" role="tablist">
            <li onClick={() => setLanguage("cg")} role="presentation">
              <a
                className={`nav-link cursor-pointer ${
                  language === "cg" ? "active" : ""
                }`}
                role="tab"
                data-bs-toggle="tab"
                aria-selected="true"
              >
                CNR
              </a>
            </li>
            <li onClick={() => setLanguage("en")} role="presentation">
              <a
                className={`nav-link cursor-pointer ${
                  language === "en" ? "active" : ""
                }`}
                role="tab"
                data-bs-toggle="tab"
                aria-selected="false"
              >
                ENG
              </a>
            </li>
          </ul>
        </div>
        <div className="card-body">
          <div className="tab-content">
            {language === "cg" && (
              <div role="tabpanel" className="tab-pane active">
                <div className="mb-3">
                  <label className="form-label">
                    Naziv <span className="form-required-filed">*</span>{" "}
                  </label>
                  <input
                    type="text"
                    name=" objects[0][naslov] "
                    className="form-control"
                    placeholder={titleCG}
                    value={nazivCNR}
                    onChange={(val) => setNazivCNRRef(val.target.value)}
                    onKeyDown={(e: any) => {
                      if (e.key === "Enter") {
                        handleSuccess(e);
                      }
                    }}
                  />
                  {requiredCG && (
                    <div className="form-error-message">
                      Naslov je obavezno polje
                    </div>
                  )}
                </div>

                {categories && (
                  <div className="form-check">
                    <input
                      checked={isChecked}
                      onChange={() => setIsChecked(!isChecked)}
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckChecked"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckChecked"
                    >
                      Da li želite dodati ovu kategoriju nekoj vec postojećoj?
                    </label>
                  </div>
                )}

                {isChecked && categories && (
                  <select
                    onChange={(e) => setParentCategory(e.target.value)}
                    className="form-select mb-3"
                    aria-label="Default select example"
                  >
                    <option value=""></option>
                    {categories.data.map((item: Daum) => (
                      <option selected={parentCategory === item.id} value={item.id}>{item.name.cg}</option>
                    ))}
                  </select>
                )}
              </div>
            )}

            {language === "en" && (
              <div role="tabpanel" className="tab-pane active">
                <div className="mb-3">
                  <label className="form-label">
                    Name <span className="form-required-filed">*</span>{" "}
                  </label>
                  <input
                    type="text"
                    name=" objects[0][naslov] "
                    className="form-control"
                    placeholder={titleEN}
                    value={nazivENG}
                    onChange={(val) => setNazivENGRef(val.target.value)}
                    onKeyDown={(e: any) => {
                      if (e.key === "Enter") {
                        handleSuccess(e);
                      }
                    }}
                  />
                  {requiredEN && (
                    <div className="form-error-message">
                      Title is required field
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="btn-list flex-nowrap justify-content-end">
          <button className="btn btn-primary-outline" onClick={onClose}>
            Obustavi
          </button>
          <button
            onClick={(e: any) => {
              handleSuccess(e);
            }}
            className="btn btn-primary"
            type="button"
          >
            Sačuvaj
          </button>
        </div>
      </div>
    </>
  );
}

export default AddEditModal;
