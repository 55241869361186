import { useEffect, useState } from "react";
import { PlusIcon } from "src/components/shared/icon/TablerIcon";
import ObjavaENGList from "./ObjavaENGList";
import ObjavaCNRList from "./ObjavaCNRList";
import { Link, useSearchParams } from "react-router-dom";
import { resetPosts, showPosts } from "src/store/objave-store";
import { useAppDispatch, useAppSelector } from "src/store";
import Pagination from "src/components/shared/Pagination";
import { BsSearch } from "react-icons/bs";

const Objave = () => {
  const dispatch = useAppDispatch();
  const { posts, CEsuccess } = useAppSelector((state) => state.post);
  const [searchParams, setSearchParams] = useSearchParams();

  const [page, setPage] = useState(parseInt(searchParams.get("page") || "1"));
  const [search, setSearch] = useState(searchParams.get("search[title]") || "");
  let data: any = {
    page,
    "search[title]": search,
    per_page: 10

  };

  useEffect(() => {
    setSearchParams(data);
    dispatch(showPosts(data));
    dispatch(resetPosts());
  }, [search, CEsuccess]);

  useEffect(() => {
    if (
      CEsuccess &&
      posts &&
      posts?.data &&
      posts?.data.length === 0 &&
      data.page &&
      data.page > 1
    ) {
      window.location.href = `/objave`;
      dispatch(resetPosts());
    }
  }, [CEsuccess]);

  return (
    <div className="objave mb-5">
      <div className="title-first row g-2 align-items-center">
        <div className="col">
          <h2 className="page-title">Objave</h2>
        </div>
        <div className="col-auto ms-auto d-print-none">
          <div className="btn-list">
            <Link
              to="/objave/nova-objava"
              className="btn btn-primary d-sm-inline-block"
            >
              <PlusIcon />
              Dodaj Objavu
            </Link>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-header">
          <ul className="nav nav-tabs card-header-tabs" role="tablist">
            <li role="presentation" className="active">
              <a
                href="#tab-1"
                className="nav-link active"
                aria-controls="#tab-1"
                role="tab"
                data-bs-toggle="tab"
                aria-selected="true"
              >
                CNR
              </a>
            </li>
            <li role="presentation">
              <a
                href="#tab-2"
                className="nav-link"
                aria-controls="#tab-2"
                role="tab"
                data-bs-toggle="tab"
                aria-selected="false"
              >
                ENG
              </a>
            </li>
          </ul>

          <div className="d-flex position-relative w-25">
            <input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              type="text"
              className="form-control input-search"
              placeholder="Pretrazite objavu "
            />
            <div className="button-search">
              <BsSearch />
            </div>
          </div>
        </div>
        <div className="card-table table-responsive">
          <div className="tab-content">
            <div role="tabpanel" className="tab-pane active" id="tab-1">
              <table className="table table-vcenter">
                <thead>
                  <tr>
                    <th>Naslov</th>
                    <th style={{ width: "50px" }}>Sadržaj</th>
                    <th>Datum</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <ObjavaCNRList data={data} listaOCNR={posts?.data} />
                </tbody>
              </table>
            </div>
            <div role="tabpanel" className="tab-pane" id="tab-2">
              <table className="table table-vcenter">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th style={{ width: "50px" }}>Content</th>
                    <th>Date</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <ObjavaENGList data={data} listaOENG={posts?.data} />
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {posts?.meta && (
          <Pagination
            meta={posts?.meta}
            setPage={setPage}
            data={data}
            switchFunction={showPosts}
            path="objave"
          />
        )}
      </div>
    </div>
  );
};

export default Objave;
