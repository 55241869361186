import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { User, UserCredentials } from "src/components/shared/models";
import api from "./interceptors/api";

interface InitialStateAuth {
  isAuthenticated: boolean;
  user: User | null;
}

const initialAuthState: InitialStateAuth = {
  isAuthenticated: JSON.parse(localStorage.getItem("user") || "null")
    ? true
    : false,
  user: JSON.parse(localStorage.getItem("user") || "null")
    ? JSON.parse(localStorage.getItem("user") || "null")
    : null,
};

export let bearerToken = `${initialAuthState.user?.access_token}`;

export const userLogin = createAsyncThunk<User, UserCredentials>(
  "user/login",
  async (credentials: UserCredentials, thunkAPI) => {
    try {
      const response = await api.post(`/auth/login`, credentials);

      bearerToken = response.data.access_token;
      localStorage.setItem("user", JSON.stringify(response.data));

      return response.data as User;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to fetch issues.");
    }
  }
);

export const userLogout = createAsyncThunk(
  "user/logout",
  async (_, thunkAPI) => {
    try {
      //const response = await api.post(`/auth/logout`, {});

      localStorage.removeItem("user");
      return true;
      //return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to fetch issues.");
    }
  }
);

const authSlice = createSlice({
  name: "authentication",
  initialState: initialAuthState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(userLogin.pending, (state) => {
        state.isAuthenticated = false;
        state.user = null;
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        state.isAuthenticated = true;
        state.user = action.payload;
      })
      .addCase(userLogin.rejected, (state) => {
        state.user = null;

        state.isAuthenticated = false;
      })
      .addCase(userLogout.pending, (state) => {
        state.isAuthenticated = false;
        state.user = null;
      })
      .addCase(userLogout.fulfilled, (state) => {
        state.isAuthenticated = false;
        state.user = null;
      })
      .addCase(userLogout.rejected, (state) => {
        state.user = null;

        state.isAuthenticated = false;
      });
  },
});

export default authSlice.reducer;
