import { DaumPost, Post } from "src/components/shared/models";
import ActionButtonsObjave from "./ActionButtonsObjave";
import moment from "moment";
import { IMAGE_LINK } from "src/data/image_link";

const ObjavaCNRList = (props: any) => {
  return (
    <>
      {props &&
        props.listaOCNR &&
        props.listaOCNR.map((lista: DaumPost) => (
          <tr key={lista.id}>
            <td className=" text-secondary">
            <div className="d-flex align-items-center">
        {lista && lista.image && lista.image.file_url &&    <img
              style={{width: 50}}
              className="me-2"
                alt="post image"
                src={`${IMAGE_LINK}/${lista.image.file_url}`}
              />}
            <p className="title">
            {lista.title.cg && lista.title.cg.length > 70
                ? lista.title.cg.slice(0, 70) + "..."
                : lista.title.cg}
            </p>
            </div>
            </td>
            <td
              className="list-center"
              dangerouslySetInnerHTML={{
                __html:
                lista.content.cg &&
                  lista.content.cg.length > 150
                    ? lista.content.cg.slice(0, 150) + "..."
                    : lista.content.cg,
              }}
            />
            <td>{moment(lista.date_created).format("DD-MM-YYYY")}</td>

            <td>
              <ActionButtonsObjave data={props.data} id={lista.id} />
            </td>
          </tr>
        ))}
    </>
  );
};

export default ObjavaCNRList;
