import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "./interceptors/api";
import { Data, Daum, Name, ReturnDataAdd, Types } from "src/components/shared/models";

interface InitialStateSolutionOptions {
  loading: boolean;
  isSuccess: boolean;
  CEsuccess: boolean;
  CEerror: boolean;
  isError: boolean;
  solutionOption: ReturnDataAdd | null;
  solutionOptions: Types | null;
}

const initialSolutionOptionsState: InitialStateSolutionOptions = {
  loading: false,
  isSuccess: false,
  isError: false,
  solutionOptions: { links: null, meta: null, data: [] },
  CEsuccess: false,
  CEerror: false,
  solutionOption: null
};

export const showSolutionOptions = createAsyncThunk(
  "solution-options/show",
  async (_, thunkAPI) => {
    try {
      const response = await api.get(`/solution-options`);
      return response.data as Types;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to fetch issues.");
    }
  }
);

export const addSolutionOptions= createAsyncThunk<ReturnDataAdd, any>(
  "solution-options/add",
  async (data: Name, thunkAPI) => {
    try {
      const response = await api.post(`/solution-options`, data);
      return response.data as ReturnDataAdd;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to fetch issues.");
    }
  }
);

export const updateSolutionOptions = createAsyncThunk<ReturnDataAdd, any>(
  "solution-options/update",
  async (data: Data, thunkAPI) => {
    try {
      const dataToSend = { ...data, _method: "PUT" };

      const response = await api.post(`/solution-options/${data.id}`, dataToSend);

      return response.data as ReturnDataAdd;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to fetch issues.");
    }
  }
);

export const deleteSolutionOptions = createAsyncThunk<number, any>(
  "solution-options/delete",
  async (id, thunkAPI) => {
    try {
      const response = await api.delete(`/solution-options/${id}`);

      return id;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to fetch issues.");
    }
  }
);

const SolutionOptionsSlice = createSlice({
  name: "solutionOptions",
  initialState: initialSolutionOptionsState,
  reducers: {
    resetSolutionOptions(state) {
      state.isSuccess = false;
      state.isError = false;
      state.CEerror = false;
      state.CEsuccess = false;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(showSolutionOptions.pending, (state) => {
        state.loading = true;
      })
      .addCase(showSolutionOptions.fulfilled, (state, action) => {
        state.loading = false;
        state.isSuccess = true;
        state.isError = false;

        state.solutionOptions = action.payload;
      })

      .addCase(showSolutionOptions.rejected, (state) => {
        state.loading = false;
        state.isError = true;
        state.isSuccess = false;
        state.solutionOptions = null;
      })


      .addCase(addSolutionOptions.pending, (state) => {
        state.loading = true;
      })
      .addCase(addSolutionOptions.fulfilled, (state, action) => {
        state.loading = false;
        state.CEsuccess = true;
        state.CEerror = false;

        state.solutionOption = action.payload;
        state.solutionOptions = state.solutionOptions &&
          state.solutionOptions.data && {
            ...state.solutionOptions,
            data: [...state.solutionOptions?.data, action.payload.data],
          };
      })

      .addCase(addSolutionOptions.rejected, (state) => {
        state.loading = false;
        state.CEerror = true;
        state.CEsuccess = false;
        state.solutionOption = null;
      })


      .addCase(updateSolutionOptions.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateSolutionOptions.fulfilled, (state, action) => {
        state.loading = false;
        state.CEsuccess = true;
        state.CEerror = false;

        state.solutionOption = action.payload;

        if (state.solutionOption && state.solutionOption.data) {
          state.solutionOptions = state.solutionOptions &&
            state.solutionOptions.data && {
              ...state.solutionOptions,
              data: state.solutionOptions?.data.map((type: Daum) => {
                if (type.id === action.payload.data.id) {
                  return action.payload.data;
                }

                return type;
              }),
            };
        }
      })
      .addCase(updateSolutionOptions.rejected, (state) => {
        state.loading = false;
        state.isError = true;
        state.isSuccess = false;
        state.solutionOption = null;
      })


      .addCase(deleteSolutionOptions.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteSolutionOptions.fulfilled, (state, action) => {
        state.loading = false;
        state.isSuccess = true;
        state.isError = false;
        state.solutionOptions = state.solutionOptions &&
          state.solutionOptions.data && {
            ...state.solutionOptions,
            data: state.solutionOptions?.data.filter((x) => x.id !== action.payload),
          };
      })
      .addCase(deleteSolutionOptions.rejected, (state) => {
        state.loading = false;
        state.isError = true;
        state.isSuccess = false;
      })
  },
});

export const { resetSolutionOptions } = SolutionOptionsSlice.actions;
export default SolutionOptionsSlice.reducer;
