import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "./interceptors/api";
import {
  DataDocuments,
  Docuemnts,
  SingleDocument,
} from "src/components/shared/models";

interface InitialStatePost {
  loading: boolean;
  isSuccess: boolean;
  CEsuccess: boolean;
  CEerror: boolean;
  isError: boolean;
  document: SingleDocument | null;
  documents: Docuemnts | null;
}

const initialDocumentState: InitialStatePost = {
  loading: false,
  isSuccess: false,
  isError: false,
  document: null,
  documents: { links: null, meta: null, data: [] },
  CEsuccess: false,
  CEerror: false,
};

export const addDocument = createAsyncThunk<SingleDocument, any>(
  "document/add",
  async (data: any, thunkAPI) => {
    try {
      const response = await api.post(`/documents`, data);
      return response.data as SingleDocument;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to fetch issues.");
    }
  }
);

export const showDocuments = createAsyncThunk(
  "documents/show",
  async (data: any, thunkAPI) => {
    data = {...data, 'sort[date_of_decision]': 'desc'}
    try {
      const response = await api.get(`/documents`, { params: data });
      return response.data as Docuemnts;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to fetch issues.");
    }
  }
);

export const deleteDocument = createAsyncThunk<Number, Number>(
  "document/delete",
  async (id: Number, thunkAPI) => {
    try {
      const response = await api.delete(`/documents/${id}`);
      return id;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to fetch issues.");
    }
  }
);

export const getDocumentById = createAsyncThunk<SingleDocument, string>(
  "document/get-document",
  async (id: string, thunkAPI) => {
    try {
      const response = await api.get(`/documents/${id}`);
      return response.data as SingleDocument;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to fetch issues.");
    }
  }
);

export const updateDocument = createAsyncThunk<SingleDocument, any>(
  "documents/update",
  async (data: { formData: any; id: string }, thunkAPI) => {
    try {
      const response = await api.post(`/documents/${data.id}`, data.formData);

      return response.data as SingleDocument;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to fetch issues.");
    }
  }
);

export const deleteDocumentFile = createAsyncThunk<any, any>(
  "document/delete-file",
  async (data: { postId: string; fileId: string; type: string }, thunkAPI) => {
    try {
      const response = await api.delete(
        `/delete-file/${data.postId}/file/${data.fileId}`
      );

      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to fetch issues.");
    }
  }
);

export const uploadDocumentsFromFile = createAsyncThunk<any, any>(
  "document/upload-documents",
  async (file, thunkAPI) => {
    try {
      const response = await api.post(`/document/import-excel`, file);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to fetch issues.");
    }
  }
);

const documentSlice = createSlice({
  name: "document",
  initialState: initialDocumentState,
  reducers: {
    resetDocuments(state) {
      state.isSuccess = false;
      state.isError = false;
      state.CEerror = false;
      state.CEsuccess = false;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(addDocument.pending, (state) => {
        state.loading = true;
      })
      .addCase(addDocument.fulfilled, (state, action) => {
        state.loading = false;
        state.CEsuccess = true;
        state.CEerror = false;

        state.document = action.payload;
        state.documents = state.documents &&
          state.documents.data && {
            ...state.documents,
            data: [...state.documents?.data, action.payload.data],
          };
      })

      .addCase(addDocument.rejected, (state) => {
        state.loading = false;
        state.CEerror = true;
        state.CEsuccess = false;
        state.document = null;
      })

      .addCase(showDocuments.pending, (state) => {
        state.loading = true;
      })
      .addCase(showDocuments.fulfilled, (state, action) => {
        state.loading = false;
        state.isSuccess = true;
        state.isError = false;

        state.documents = action.payload;
      })

      .addCase(showDocuments.rejected, (state) => {
        state.loading = false;
        state.isError = true;
        state.isSuccess = false;
        state.document = null;
        state.documents = null;
      })

      .addCase(deleteDocument.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteDocument.fulfilled, (state, action) => {
        state.loading = false;
        state.isSuccess = true;
        state.isError = false;
        state.documents = state.documents &&
          state.documents.data && {
            ...state.documents,
            data: state.documents?.data.filter((x) => x.id !== action.payload),
          };
      })
      .addCase(deleteDocument.rejected, (state) => {
        state.loading = false;
        state.isError = true;
        state.isSuccess = false;
      })

      .addCase(getDocumentById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDocumentById.fulfilled, (state, action) => {
        state.loading = false;
        state.isSuccess = true;
        state.isError = false;
        state.document = action.payload;
      })
      .addCase(getDocumentById.rejected, (state) => {
        state.loading = false;
        state.isError = true;
        state.isSuccess = false;
      })

      .addCase(updateDocument.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateDocument.fulfilled, (state, action) => {
        state.loading = false;
        state.CEsuccess = true;
        state.CEerror = false;

        state.document = action.payload;
        if (state.documents && state.documents.data) {
          state.documents = state.documents &&
            state.documents.data && {
              ...state.documents,
              data: state.documents?.data.map((document: any) => {
                if (document.id === action.payload.data.id) {
                  return action.payload.data;
                }

                return document;
              }),
            };
        }
      })

      .addCase(updateDocument.rejected, (state) => {
        state.loading = false;
        state.CEerror = true;
        state.CEsuccess = false;
        state.document = null;
      })

      .addCase(deleteDocumentFile.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteDocumentFile.fulfilled, (state, action) => {
        state.loading = false;
        state.isSuccess = true;
        state.isError = false;
        state.document = state.document &&
          state.document.data.documents && {
            ...state.document,
            data: {
              ...state.document.data,
              documents: state.document?.data.documents.filter(
                (x) => x.id !== action.payload.fileId
              ),
            },
          };
      })
      .addCase(deleteDocumentFile.rejected, (state) => {
        state.loading = false;
        state.isError = true;
        state.isSuccess = false;
      })

      .addCase(uploadDocumentsFromFile.pending, (state) => {
        state.loading = true;
      })
      .addCase(uploadDocumentsFromFile.fulfilled, (state, action) => {
        state.loading = false;
        state.CEsuccess = true;
        state.isError = false;
      })
      .addCase(uploadDocumentsFromFile.rejected, (state) => {
        state.loading = false;
        state.isError = true;
        state.CEsuccess = false;
      });
  },
});

export const { resetDocuments } = documentSlice.actions;
export default documentSlice.reducer;
