import { useEffect } from 'react'
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'src/store';
import { addSolutionType, deleteSolutionType, resetSolutionTypes, showSolutionTypes, updateSolutionType } from 'src/store/tip_rjesenja-store';
import TableForItems from 'src/components/shared/TableForItems';

function TipRjesenja() {
    const { solutionTypes, loading, CEsuccess } = useAppSelector(
        (state) => state.solutionTypes
      );
      const { user } = useAppSelector((state) => state.login);
      const dispatch = useAppDispatch();
    
      useEffect(() => {
        if (CEsuccess) {
          toast.success("Success");
          dispatch(resetSolutionTypes())

        }
      }, [CEsuccess, dispatch]);
    
      useEffect(() => {
        if (user) {
          dispatch(showSolutionTypes());
        }
      }, [dispatch, user]);
    
    
      return (
        <>
      {solutionTypes && (
        <TableForItems
          title="Tip rjesenja"
          titleButton="Dodaj tip rjesenja"
          titleCg="Naziv tip rjesenja"
          titleEn="Solution type title"
          deleteFunction={deleteSolutionType}
          addFunction={addSolutionType}
          editFunction={updateSolutionType}
          data={solutionTypes}
        />
      )}
    </>
      )
}

export default TipRjesenja