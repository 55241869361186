import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { DaumPost, Post, Posts } from "src/components/shared/models";
import api from "./interceptors/api";

interface InitialStatePost {
  loading: boolean;
  isSuccess: boolean;
  CEsuccess: boolean;
  CEerror: boolean;
  isError: boolean;
  post: Post | null;
  posts: Posts | null;
}

const initialPostState: InitialStatePost = {
  loading: false,
  isSuccess: false,
  isError: false,
  post: null,
  posts: { links: null, meta: null, data: [] },
  CEsuccess: false,
  CEerror: false,
};

export const addPost = createAsyncThunk<Post, any>(
  "post/add",
  async (data: any, thunkAPI) => {
    try {
      const response = await api.post(`/posts`, data);
      return response.data as Post;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to fetch issues.");
    }
  }
);

export const showPosts = createAsyncThunk(
  "post/show",
  async (data: any, thunkAPI) => {
    try {
      const response = await api.get(`/posts`, { params: data });
      return response.data as Posts;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to fetch issues.");
    }
  }
);

export const deletePost = createAsyncThunk<Number, Number>(
  "post/delete",
  async (id: Number, thunkAPI) => {
    try {
      const response = await api.delete(`/posts/${id}`);
      return id;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to fetch issues.");
    }
  }
);

export const getPostById = createAsyncThunk<Post, string>(
  "post/get-post",
  async (id: string, thunkAPI) => {
    try {
      const response = await api.get(`/posts/${id}`);
      return response.data as Post;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to fetch issues.");
    }
  }
);

export const updatePost = createAsyncThunk<Post, any>(
  "posts/update",
  async (data: { formData: DaumPost; id: string }, thunkAPI) => {
    try {
      const response = await api.post(`/posts/${data.id}`, data.formData);

      return response.data as Post;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to fetch issues.");
    }
  }
);

export const deletePostFile = createAsyncThunk<any, any>(
  "posts/delete-file",
  async (data: { postId: string; fileId: string; type: string }, thunkAPI) => {
    try {
      const response = await api.delete(
        `/post-file/${data.postId}/file/${data.fileId}`
      );

      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to fetch issues.");
    }
  }
);

export const removePostCategory = createAsyncThunk<any, any>(
  "posts/remove-category",
  async (data: { postId: string; categoryId: string }, thunkAPI) => {
    try {
      const response = await api.delete(
        `/post-category/${data.postId}/category/${data.categoryId}`
      );

      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to fetch issues.");
    }
  }
);

const postSlice = createSlice({
  name: "post",
  initialState: initialPostState,
  reducers: {
    resetPosts(state) {
      state.isSuccess = false;
      state.isError = false;
      state.CEerror = false;
      state.CEsuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addPost.pending, (state) => {
        state.loading = true;
      })
      .addCase(addPost.fulfilled, (state, action) => {
        state.loading = false;
        state.CEsuccess = true;
        state.CEerror = false;

        state.post = action.payload;
        state.posts = state.posts &&
          state.posts.data && {
            ...state.posts,
            data: [...state.posts?.data, action.payload.data],
          };
      })

      .addCase(addPost.rejected, (state) => {
        state.loading = false;
        state.CEerror = true;
        state.CEsuccess = false;
        state.post = null;
      })

      .addCase(updatePost.pending, (state) => {
        state.loading = true;
      })
      .addCase(updatePost.fulfilled, (state, action) => {
        state.loading = false;
        state.CEsuccess = true;
        state.CEerror = false;

        state.post = action.payload;
        if (state.posts && state.posts.data) {
          state.posts = state.posts &&
            state.posts.data && {
              ...state.posts,
              data: state.posts?.data.map((post: DaumPost) => {
                if (post.id === action.payload.data.id) {
                  return action.payload.data;
                }

                return post;
              }),
            };
        }
      })

      .addCase(updatePost.rejected, (state) => {
        state.loading = false;
        state.CEerror = true;
        state.CEsuccess = false;
        state.post = null;
      })

      .addCase(showPosts.pending, (state) => {
        state.loading = true;
      })
      .addCase(showPosts.fulfilled, (state, action) => {
        state.loading = false;
        state.isSuccess = true;
        state.isError = false;

        state.posts = action.payload;
      })

      .addCase(showPosts.rejected, (state) => {
        state.loading = false;
        state.isError = true;
        state.isSuccess = false;
        state.post = null;
        state.posts = null;
      })

      .addCase(deletePost.pending, (state) => {
        state.loading = true;
      })
      .addCase(deletePost.fulfilled, (state, action) => {
        state.loading = false;
        state.CEsuccess = true;
        state.isError = false;
        state.posts = state.posts &&
          state.posts.data && {
            ...state.posts,
            data: state.posts?.data.filter((x) => x.id !== action.payload),
          };
      })
      .addCase(deletePost.rejected, (state) => {
        state.loading = false;
        state.isError = true;
        state.isSuccess = false;
      })

      .addCase(getPostById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPostById.fulfilled, (state, action) => {
        state.loading = false;
        state.isSuccess = true;
        state.isError = false;
        state.post = action.payload;
      })
      .addCase(getPostById.rejected, (state) => {
        state.loading = false;
        state.isError = true;
        state.isSuccess = false;
      })

      .addCase(deletePostFile.pending, (state) => {
        state.loading = true;
      })
      .addCase(deletePostFile.fulfilled, (state, action) => {
        state.loading = false;
        state.isSuccess = true;
        state.isError = false;
        if (action.payload.type === "images") {
          state.post = state.post &&
            state.post.data.images && {
              ...state.post,
              data: {
                ...state.post.data,
                images: state.post?.data.images.filter(
                  (x) => x.id !== action.payload.fileId
                ),
              },
            };
        } else if (action.payload.type === "documents") {
          state.post = state.post &&
            state.post.data.documents && {
              ...state.post,
              data: {
                ...state.post.data,
                documents: state.post?.data.documents.filter(
                  (x) => x.id !== action.payload.fileId
                ),
              },
            };
        }
      })
      .addCase(deletePostFile.rejected, (state) => {
        state.loading = false;
        state.isError = true;
        state.isSuccess = false;
      })
  },
});

export const { resetPosts } = postSlice.actions;
export default postSlice.reducer;
