import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "./interceptors/api";
import { Data, Daum, Name, ReturnDataAdd, Types } from "src/components/shared/models";

interface InitialStateSubjectTypes {
  loading: boolean;
  isSuccess: boolean;
  CEsuccess: boolean;
  CEerror: boolean;
  isError: boolean;
  subjectType: ReturnDataAdd | null;
  subjectTypes: Types | null;
}

const initialSubjectTypesState: InitialStateSubjectTypes = {
  loading: false,
  isSuccess: false,
  isError: false,
  subjectTypes: { links: null, meta: null, data: [] },
  CEsuccess: false,
  CEerror: false,
  subjectType: null
};

export const showSubjectTypes = createAsyncThunk(
  "subject-types/show",
  async (_, thunkAPI) => {
    try {
      const response = await api.get(`/subject-types`);
      return response.data as Types;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to fetch issues.");
    }
  }
);


export const addSubjectTypes= createAsyncThunk<ReturnDataAdd, any>(
  "subject-types/add",
  async (data: Name, thunkAPI) => {
    try {
      const response = await api.post(`/subject-types`, data);
      return response.data as ReturnDataAdd;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to fetch issues.");
    }
  }
);

export const updateSubjectTypes = createAsyncThunk<ReturnDataAdd, any>(
  "subject-types/update",
  async (data: Data, thunkAPI) => {
    try {
      const dataToSend = { ...data, _method: "PUT" };

      const response = await api.post(`/subject-types/${data.id}`, dataToSend);

      return response.data as ReturnDataAdd;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to fetch issues.");
    }
  }
);

export const deleteSubjectTypes = createAsyncThunk<number, any>(
  "subject-types/delete",
  async (id, thunkAPI) => {
    try {
      const response = await api.delete(`/subject-types/${id}`);

      return id;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to fetch issues.");
    }
  }
);

const subjectTypesSlice = createSlice({
  name: "subjectTypes ",
  initialState: initialSubjectTypesState,
  reducers: {
    resetSubjectTypes(state) {
      state.isSuccess = false;
      state.isError = false;
      state.CEerror = false;
      state.CEsuccess = false;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(showSubjectTypes.pending, (state) => {
        state.loading = true;
      })
      .addCase(showSubjectTypes.fulfilled, (state, action) => {
        state.loading = false;
        state.isSuccess = true;
        state.isError = false;

        state.subjectTypes = action.payload;
      })

      .addCase(showSubjectTypes.rejected, (state) => {
        state.loading = false;
        state.isError = true;
        state.isSuccess = false;
        state.subjectTypes = null;
      })
      .addCase(addSubjectTypes.pending, (state) => {
        state.loading = true;
      })
      .addCase(addSubjectTypes.fulfilled, (state, action) => {
        state.loading = false;
        state.CEsuccess = true;
        state.CEerror = false;

        state.subjectType = action.payload;
        state.subjectTypes = state.subjectTypes &&
          state.subjectTypes.data && {
            ...state.subjectTypes,
            data: [...state.subjectTypes?.data, action.payload.data],
          };
      })

      .addCase(addSubjectTypes.rejected, (state) => {
        state.loading = false;
        state.CEerror = true;
        state.CEsuccess = false;
        state.subjectType = null;
      })


      .addCase(updateSubjectTypes.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateSubjectTypes.fulfilled, (state, action) => {
        state.loading = false;
        state.CEsuccess = true;
        state.CEerror = false;

        state.subjectType = action.payload;

        if (state.subjectType && state.subjectType.data) {
          state.subjectTypes = state.subjectTypes &&
            state.subjectTypes.data && {
              ...state.subjectTypes,
              data: state.subjectTypes?.data.map((type: Daum) => {
                if (type.id === action.payload.data.id) {
                  return action.payload.data;
                }

                return type;
              }),
            };
        }
      })
      .addCase(updateSubjectTypes.rejected, (state) => {
        state.loading = false;
        state.isError = true;
        state.isSuccess = false;
        state.subjectType = null;
      })


      .addCase(deleteSubjectTypes.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteSubjectTypes.fulfilled, (state, action) => {
        state.loading = false;
        state.isSuccess = true;
        state.isError = false;
        state.subjectTypes = state.subjectTypes &&
          state.subjectTypes.data && {
            ...state.subjectTypes,
            data: state.subjectTypes?.data.filter((x) => x.id !== action.payload),
          };
      })
      .addCase(deleteSubjectTypes.rejected, (state) => {
        state.loading = false;
        state.isError = true;
        state.isSuccess = false;
      })
  },
});

export const { resetSubjectTypes } = subjectTypesSlice.actions;
export default subjectTypesSlice.reducer;
