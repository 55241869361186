import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "./login-store";
import categoryReducer from "./kategorija-store";
import postReducer from "./objave-store";
import documentReducer from "./dokument-store";
import solutionTypesReducer from "./tip_rjesenja-store";
import solutionOptionsReducer from "./vrsta_rjesenja-store";
import subjectTypesReducer from "./vrsta_predmeta-store";
import sPublicProcurementReducer from "./p_oblici_j_n-store";
import procedureTypesReducer from "./vrsta_postupka-store";
import procurementTypesReducer from "./vrsta_nabavke-store";

import uiSlice from "./ui-slice";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

const store = configureStore({
  reducer: {
    login: loginReducer,
    ui: uiSlice.reducer,
    category: categoryReducer,
    post: postReducer,
    document: documentReducer,
    solutionTypes: solutionTypesReducer,
    solutionOptions: solutionOptionsReducer,
    subjectTypes: subjectTypesReducer,
    sPublicProcurement: sPublicProcurementReducer,
    procedureTypes: procedureTypesReducer,
    procurementTypes: procurementTypesReducer,
  },
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
