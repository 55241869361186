import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  Categories,
  ReturnDataAdd,
  Data,
  Daum,
  Name,
} from "src/components/shared/models";
import api from "./interceptors/api";

interface InitialStateCategory {
  loading: boolean;
  category: ReturnDataAdd | null;
  isSuccess: boolean;
  CEsuccess: boolean;
  CEerror: boolean;
  isError: boolean;
  categories: Categories | null;
  addCategories: Categories | null
}

const initialcategoryState: InitialStateCategory = {
  loading: false,
  category: null,
  isSuccess: false,
  isError: false,
  categories: { links: null, meta: null, data: [] },
  CEerror: false,
  CEsuccess: false,
  addCategories: null
};

export const addCategory = createAsyncThunk<ReturnDataAdd, any>(
  "category/add",
  async (data: Name, thunkAPI) => {
    try {
      const response = await api.post(`/categories`, data);
      return response.data as ReturnDataAdd;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to fetch issues.");
    }
  }
);

export const updateCategory = createAsyncThunk<ReturnDataAdd, any>(
  "category/update",
  async (data: Data, thunkAPI) => {
    try {
      const dataToSend = { ...data, _method: "PUT" };

      const response = await api.post(`/categories/${data.id}`, dataToSend);

      return response.data as ReturnDataAdd;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to fetch issues.");
    }
  }
);

export const showCategories = createAsyncThunk(
  "category/show",
  async (data:any, thunkAPI) => {
    try {
      const response = await api.get(`/categories`,{params: data});

      return response.data as Categories;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to fetch issues.");
    }
  }
);


export const showCategoriesForAdd = createAsyncThunk(
  "category/show-add",
  async (data:any, thunkAPI) => {
    try {
      const response = await api.get(`/categories`,{params: data});

      return response.data as Categories;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to fetch issues.");
    }
  }
);

export const deleteCategory = createAsyncThunk<number, any>(
  "category/delete",
  async (id, thunkAPI) => {
    try {
      const response = await api.delete(`/categories/${id}`);

      return id;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to fetch issues.");
    }
  }
);

const categorySlice = createSlice({
  name: "category",
  initialState: initialcategoryState,
  reducers: {
    resetCategory(state) {
      state.CEerror = false;
      state.CEsuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addCategory.pending, (state) => {
        state.loading = true;
      })
      .addCase(addCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.CEsuccess = true;
        state.CEerror = false;

        state.category = action.payload;
        state.categories = state.categories &&
          state.categories.data && {
            ...state.categories,
            data: [...state.categories?.data, action.payload.data],
          };
      })
      .addCase(addCategory.rejected, (state) => {
        state.loading = false;
        state.CEerror = true;
        state.CEsuccess = false;
        state.category = null;
      })

      .addCase(showCategories.pending, (state) => {
        state.loading = true;
      })
      .addCase(showCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.isSuccess = true;
        state.isError = false;

        state.categories = action.payload;
      })
      
      .addCase(showCategories.rejected, (state) => {
        state.loading = false;
        state.isError = true;
        state.isSuccess = false;
        state.categories = null;
      })



      .addCase(showCategoriesForAdd.pending, (state) => {
        state.loading = true;
      })
      .addCase(showCategoriesForAdd.fulfilled, (state, action) => {
        state.loading = false;
        state.isSuccess = true;
        state.isError = false;

        state.addCategories = action.payload;
      })
      
      .addCase(showCategoriesForAdd.rejected, (state) => {
        state.loading = false;
        state.isError = true;
        state.isSuccess = false;
        state.categories = null;
      })

      .addCase(deleteCategory.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.isSuccess = true;
        state.isError = false;
        state.categories = state.categories &&
          state.categories.data && {
            ...state.categories,
            data: state.categories?.data.filter((x) => x.id !== action.payload),
          };
      })
      .addCase(deleteCategory.rejected, (state) => {
        state.loading = false;
        state.isError = true;
        state.isSuccess = false;
      })

      .addCase(updateCategory.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.CEsuccess = true;
        state.CEerror = false;

        state.category = action.payload;

        if (state.categories && state.categories.data) {
          state.categories = state.categories &&
            state.categories.data && {
              ...state.categories,
              data: state.categories?.data.map((category: Daum) => {
                if (category.id === action.payload.data.id) {
                  return action.payload.data;
                }

                return category;
              }),
            };
        }
      })
      .addCase(updateCategory.rejected, (state) => {
        state.loading = false;
        state.isError = true;
        state.isSuccess = false;
        state.category = null;
      });
  },
});
export const { resetCategory } = categorySlice.actions;
export default categorySlice.reducer;
