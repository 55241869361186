import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "./interceptors/api";
import { Data, Daum, Name, ReturnDataAdd, Types } from "src/components/shared/models";

interface InitialStateSPublicProcurement {
  loading: boolean;
  isSuccess: boolean;
  CEsuccess: boolean;
  CEerror: boolean;
  isError: boolean;
  sPublicProcurement: ReturnDataAdd | null;
  sPublicProcurements: Types | null;
}

const initialsPublicProcurementState: InitialStateSPublicProcurement = {
  loading: false,
  isSuccess: false,
  isError: false,
  sPublicProcurements: { links: null, meta: null, data: [] },
  CEsuccess: false,
  CEerror: false,
  sPublicProcurement: null
};

export const showSPublicProcurement = createAsyncThunk(
  "special-public-procurements/show",
  async (_, thunkAPI) => {
    try {
      const response = await api.get(`/special-public-procurements`);
      return response.data as Types;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to fetch issues.");
    }
  }
);


export const addSPublicProcurement= createAsyncThunk<ReturnDataAdd, any>(
  "special-public-procurements/add",
  async (data: Name, thunkAPI) => {
    try {
      const response = await api.post(`/special-public-procurements`, data);
      return response.data as ReturnDataAdd;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to fetch issues.");
    }
  }
);

export const updateSPublicProcurement = createAsyncThunk<ReturnDataAdd, any>(
  "special-public-procurements/update",
  async (data: Data, thunkAPI) => {
    try {
      const dataToSend = { ...data, _method: "PUT" };

      const response = await api.post(`/special-public-procurements/${data.id}`, dataToSend);

      return response.data as ReturnDataAdd;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to fetch issues.");
    }
  }
);

export const deleteSPublicProcurement = createAsyncThunk<number, any>(
  "special-public-procurements/delete",
  async (id, thunkAPI) => {
    try {
      const response = await api.delete(`/special-public-procurements/${id}`);

      return id;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to fetch issues.");
    }
  }
);


const sPublicProcurementSlice = createSlice({
  name: "sPublicProcurement ",
  initialState: initialsPublicProcurementState,
  reducers: {
    resetSPublicProcurement(state) {
      state.isSuccess = false;
      state.isError = false;
      state.CEerror = false;
      state.CEsuccess = false;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(showSPublicProcurement.pending, (state) => {
        state.loading = true;
      })
      .addCase(showSPublicProcurement.fulfilled, (state, action) => {
        state.loading = false;
        state.isSuccess = true;
        state.isError = false;

        state.sPublicProcurements = action.payload;
      })

      .addCase(showSPublicProcurement.rejected, (state) => {
        state.loading = false;
        state.isError = true;
        state.isSuccess = false;
        state.sPublicProcurement = null;
      })




      .addCase(addSPublicProcurement.pending, (state) => {
        state.loading = true;
      })
      .addCase(addSPublicProcurement.fulfilled, (state, action) => {
        state.loading = false;
        state.CEsuccess = true;
        state.CEerror = false;

        state.sPublicProcurement = action.payload;
        state.sPublicProcurements = state.sPublicProcurements &&
          state.sPublicProcurements.data && {
            ...state.sPublicProcurements,
            data: [...state.sPublicProcurements?.data, action.payload.data],
          };
      })

      .addCase(addSPublicProcurement.rejected, (state) => {
        state.loading = false;
        state.CEerror = true;
        state.CEsuccess = false;
        state.sPublicProcurement = null;
      })


      .addCase(updateSPublicProcurement.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateSPublicProcurement.fulfilled, (state, action) => {
        state.loading = false;
        state.CEsuccess = true;
        state.CEerror = false;

        state.sPublicProcurement = action.payload;

        if (state.sPublicProcurement && state.sPublicProcurement.data) {
          state.sPublicProcurements = state.sPublicProcurements &&
            state.sPublicProcurement.data && {
              ...state.sPublicProcurements,
              data: state.sPublicProcurements?.data.map((type: Daum) => {
                if (type.id === action.payload.data.id) {
                  return action.payload.data;
                }

                return type;
              }),
            };
        }
      })
      .addCase(updateSPublicProcurement.rejected, (state) => {
        state.loading = false;
        state.isError = true;
        state.isSuccess = false;
        state.sPublicProcurement = null;
      })


      .addCase(deleteSPublicProcurement.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteSPublicProcurement.fulfilled, (state, action) => {
        state.loading = false;
        state.isSuccess = true;
        state.isError = false;
        state.sPublicProcurements = state.sPublicProcurements &&
          state.sPublicProcurements.data && {
            ...state.sPublicProcurements,
            data: state.sPublicProcurements?.data.filter((x) => x.id !== action.payload),
          };
      })
      .addCase(deleteSPublicProcurement.rejected, (state) => {
        state.loading = false;
        state.isError = true;
        state.isSuccess = false;
      })
  },
});

export const { resetSPublicProcurement } = sPublicProcurementSlice.actions;
export default sPublicProcurementSlice.reducer;
