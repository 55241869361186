import classes from './Notification.module.scss';

const Notification = (props:any) => {
  let specialClasses = '';

  if (props.status === 'error') {
    specialClasses = classes.error;
  }
  if (props.status === 'success') {
    specialClasses = classes.success;
  }

  const cssClasses = `${classes.notification} ${specialClasses}`; // ova prva kasa je default ova drugo zavisi od "if"


  return (
    <section id='notification'>
      <div className={cssClasses}>
      <h2>{props.title}</h2>
      </div>
    </section>
  );

};

export default Notification;