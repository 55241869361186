import { useState } from "react";
import { DeleteIcon, EditIcon } from "../../components/shared/icon/TablerIcon";
import Modal from "src/components/modal/Modal";
import { Name } from "../../components/shared/models";
import ModalDelete from "src/components/shared/ModalDelete";
import { Link } from "react-router-dom";
import { deleteDocument } from "src/store/dokument-store";

interface ActionButtonProps {
  id: number;
  data?: Name;
}

function ActionButtonDokumenti({ id, data }: ActionButtonProps) {
  const [showModalDelete, setShowModalDelete] = useState(false);

  const handleCloseDelete = () => setShowModalDelete(false);
  const handleShowDelete = () => setShowModalDelete(true);

  return (
    <>
      <div className="btn-list flex-nowrap">
        <Link
          to={`/dokumenta/edit-dokument/${id}`}
          className="btn"
          title="Izmijeni"
        >
          <EditIcon />
        </Link>
        <button onClick={handleShowDelete} className="btn" title="Izbriši">
          <DeleteIcon />
        </button>
      </div>

      {showModalDelete && (
        <Modal onClose={handleCloseDelete}>
          <ModalDelete deleteItem={deleteDocument} onClose={handleCloseDelete} id={id} />
        </Modal>
      )}
    </>
  );
}

export default ActionButtonDokumenti;
