import { useEffect } from 'react'
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'src/store';
import { addSubjectTypes, deleteSubjectTypes, resetSubjectTypes, showSubjectTypes, updateSubjectTypes } from 'src/store/vrsta_predmeta-store';
import TableForItems from 'src/components/shared/TableForItems';

function VrstaPredmeta() {
    const { subjectTypes, CEsuccess } = useAppSelector(
        (state) => state.subjectTypes
      );
      const { user } = useAppSelector((state) => state.login);
      const dispatch = useAppDispatch();
    
      useEffect(() => {
        if (CEsuccess) {
          toast.success("Success");
          dispatch(resetSubjectTypes())

        }
      }, [CEsuccess, dispatch]);
    
      useEffect(() => {
        if (user) {
          dispatch(showSubjectTypes());
        }
      }, [dispatch, user]);
    
    
      return (
        <>
        {subjectTypes && (
          <TableForItems
            title="Vrsta predmeta"
            titleButton="Dodaj vrstu predmeta"
            titleCg="Naziv vrste predmeta"
            titleEn="Subject types title"
            deleteFunction={deleteSubjectTypes}
            addFunction={addSubjectTypes}
            editFunction={updateSubjectTypes}
            data={subjectTypes}
          />
        )}
      </>
      )
}

export default VrstaPredmeta