import React, { useEffect } from "react";
import ObjaveForm from "./ObjaveForm";
import { useLocation, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/store";
import { getPostById } from "src/store/objave-store";

function EditObjava() {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { post } = useAppSelector((state) => state.post);
  const location = useLocation()
  const {data} = location.state

  useEffect(() => {
    if (id) {
      dispatch(getPostById(id));
    }
  }, [id, dispatch]);
  return (
    <>
      {post && id === post.data.id.toString() && (
        <ObjaveForm searchData={data} is_edit={true} id={id} data={post} />
      )}
    </>
  );
}

export default EditObjava;
