import React, { useState } from "react";
import "./multiselect.scss";
import { vrsteRjesenja } from "src/data/vrste_rjesenja";
import { AiOutlineCheck } from "react-icons/ai";

interface Props {
  solutionOptions: string[];
  setSolutionOptions: (data: any) => any;
}

function Multiselect({ solutionOptions, setSolutionOptions }: Props) {
  const [open, setOpen] = useState(false);

  const handleCheckboxChangeSolutionOptions = (value: string) => {
    const isInArray = isOptionInArray(value);
    if (!isInArray) {
      setSolutionOptions([...solutionOptions, value]);
    } else {
      setSolutionOptions(solutionOptions.filter((x: any) => x !== value));
    }
  };
  const isOptionInArray = (value: string) => {
    return solutionOptions.find((item) => item === value);
  };
  return (
    <>
      <div
        onClick={() => setOpen(!open)}
        className="form-control cursor-pointer"
      >
       {solutionOptions.length ===0 ? "Odaberite vrstu rjesenja" : `${solutionOptions.length} odabrano`}
      </div>

      {open && (
        <ul className="multi-select-choises">
          {vrsteRjesenja.map((item: any) => (
            <>
              <li className="multi-select-title ">{item.title}</li>
              {item.children.map((child: any) => (
                <li
                  onClick={() => handleCheckboxChangeSolutionOptions(child)}
                  className="multi-select-item active-item"
                >
                  {isOptionInArray(child) && <AiOutlineCheck color="green" />}{" "}
                  {child}
                </li>
              ))}
            </>
          ))}
        </ul>
      )}
    </>
  );
}

export default Multiselect;
