import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "./interceptors/api";
import { Data, Daum, Name, ReturnDataAdd, Types } from "src/components/shared/models";

interface InitialStateSolutionTypes {
  loading: boolean;
  isSuccess: boolean;
  CEsuccess: boolean;
  CEerror: boolean;
  isError: boolean;
  solutionType: ReturnDataAdd | null;
  solutionTypes: Types | null;
}

const initialSolutionTypesState: InitialStateSolutionTypes = {
  loading: false,
  isSuccess: false,
  isError: false,
  solutionTypes: { links: null, meta: null, data: [] },
  CEsuccess: false,
  CEerror: false,
  solutionType: null
};

export const showSolutionTypes = createAsyncThunk(
  "solutionTypes/show",
  async (_, thunkAPI) => {
    try {
      const response = await api.get(`/solution-types`);
      return response.data as Types;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to fetch issues.");
    }
  }
);

export const addSolutionType= createAsyncThunk<ReturnDataAdd, any>(
  "solution-types/add",
  async (data: Name, thunkAPI) => {
    try {
      const response = await api.post(`/solution-types`, data);
      return response.data as ReturnDataAdd;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to fetch issues.");
    }
  }
);

export const updateSolutionType = createAsyncThunk<ReturnDataAdd, any>(
  "solution-types/update",
  async (data: Data, thunkAPI) => {
    try {
      const dataToSend = { ...data, _method: "PUT" };

      const response = await api.post(`/solution-types/${data.id}`, dataToSend);

      return response.data as ReturnDataAdd;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to fetch issues.");
    }
  }
);

export const deleteSolutionType = createAsyncThunk<number, any>(
  "solution-types/delete",
  async (id, thunkAPI) => {
    try {
      const response = await api.delete(`/solution-types/${id}`);

      return id;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to fetch issues.");
    }
  }
);

const solutionTypes = createSlice({
  name: "solutionTypes",
  initialState: initialSolutionTypesState,
  reducers: {
    resetSolutionTypes(state) {
      state.isSuccess = false;
      state.isError = false;
      state.CEerror = false;
      state.CEsuccess = false;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(showSolutionTypes.pending, (state) => {
        state.loading = true;
      })
      .addCase(showSolutionTypes.fulfilled, (state, action) => {
        state.loading = false;
        state.isSuccess = true;
        state.isError = false;

        state.solutionTypes = action.payload;
      })

      .addCase(showSolutionTypes.rejected, (state) => {
        state.loading = false;
        state.isError = true;
        state.isSuccess = false;
        state.solutionTypes = null;
      })

      .addCase(addSolutionType.pending, (state) => {
        state.loading = true;
      })
      .addCase(addSolutionType.fulfilled, (state, action) => {
        state.loading = false;
        state.CEsuccess = true;
        state.CEerror = false;

        state.solutionType = action.payload;
        state.solutionTypes = state.solutionTypes &&
          state.solutionTypes.data && {
            ...state.solutionTypes,
            data: [...state.solutionTypes?.data, action.payload.data],
          };
      })

      .addCase(addSolutionType.rejected, (state) => {
        state.loading = false;
        state.CEerror = true;
        state.CEsuccess = false;
        state.solutionType = null;
      })


      .addCase(updateSolutionType.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateSolutionType.fulfilled, (state, action) => {
        state.loading = false;
        state.CEsuccess = true;
        state.CEerror = false;

        state.solutionType = action.payload;

        if (state.solutionType && state.solutionType.data) {
          state.solutionTypes = state.solutionTypes &&
            state.solutionTypes.data && {
              ...state.solutionTypes,
              data: state.solutionTypes?.data.map((type: Daum) => {
                if (type.id === action.payload.data.id) {
                  return action.payload.data;
                }

                return type;
              }),
            };
        }
      })
      .addCase(updateSolutionType.rejected, (state) => {
        state.loading = false;
        state.isError = true;
        state.isSuccess = false;
        state.solutionType = null;
      })


      .addCase(deleteSolutionType.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteSolutionType.fulfilled, (state, action) => {
        state.loading = false;
        state.isSuccess = true;
        state.isError = false;
        state.solutionTypes = state.solutionTypes &&
          state.solutionTypes.data && {
            ...state.solutionTypes,
            data: state.solutionTypes?.data.filter((x) => x.id !== action.payload),
          };
      })
      .addCase(deleteSolutionType.rejected, (state) => {
        state.loading = false;
        state.isError = true;
        state.isSuccess = false;
      })
  },
});

export const { resetSolutionTypes } = solutionTypes.actions;
export default solutionTypes.reducer;
