import { useEffect } from "react";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "src/store";
import {
  addProcedureTypes,
  deleteProcedureTypes,
  resetPocedureTypes,
  showProcedureTypes,
  updateProcedureTypes,
} from "src/store/vrsta_postupka-store";
import TableForItems from "src/components/shared/TableForItems";

function VrstaPostupka() {
  const { procedureTypes, loading, CEsuccess } = useAppSelector(
    (state) => state.procedureTypes
  );
  const { user } = useAppSelector((state) => state.login);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (CEsuccess) {
      toast.success("Success");
      dispatch(resetPocedureTypes())

    }
  }, [CEsuccess, dispatch]);

  useEffect(() => {
    if (user) {
      dispatch(showProcedureTypes());
    }
  }, [dispatch, user]);

  return (
    <>
      {procedureTypes && (
        <TableForItems
          title="Vrsta postupka"
          titleButton="Dodaj vrstu postupka"
          titleCg="Naziv vrste postupka"
          titleEn="Procedure types title"
          deleteFunction={deleteProcedureTypes}
          addFunction={addProcedureTypes}
          editFunction={updateProcedureTypes}
          data={procedureTypes}
        />
      )}
    </>
  );
}

export default VrstaPostupka;
