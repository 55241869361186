import React from "react";
import { useNavigate } from "react-router-dom";
import { Meta } from "./models";
import { useDispatch } from "react-redux";

interface Props {
  meta: Meta;
  switchFunction: (data: any) => any;
  data: any;
  setPage: (page: number) => any;
  path: string;
}

function Pagination({ meta, switchFunction, data, setPage, path }: Props) {
  const dispatch = useDispatch();
  const naviagte = useNavigate();

  const renderNumbers = () => {
    const listItems = [];
    const totalPages = meta.last_page;
    const currentPage = meta.current_page;
  
    // Display up to 10 pages, centered around the current page
    let startPage = Math.max(1, currentPage - 4);
    let endPage = Math.min(totalPages, startPage + 9);
  
    // Adjust if the endPage is less than 10
    if (endPage - startPage + 1 < 10) {
      startPage = Math.max(1, endPage - 9);
    }
  
    for (let i = startPage; i <= endPage; i++) {
      listItems.push(
        <li
          key={`pageitem${i}`}
          onClick={() => onPageClicked(i)}
          className={`page-item ${
            i === currentPage ? "disabled" : "cursor-pointer"
          }`}
        >
          <span className="page-link">{i}</span>
        </li>
      );
    }
  
    return listItems;
  };

  const onPageClicked = (page: number) => {
    data.page = page;
    dispatch(switchFunction(data));
    setPage(page);
    naviagte(
      `/${path}?page=${page}${
        path === "objave" ? `&search[title]=${data["search[title]"]}` : ''
      }`
    );
  };

  return (
    <nav aria-label="Pagination">
      <ul className="pagination justify-content-end">
        <li
          onClick={() => onPageClicked(1)}
          className={`page-item ${meta.current_page === 1 ? "disabled" : ""}`}
        >
          <span className="page-link" aria-label="Previous">
            <span aria-hidden="true">&laquo;</span>
          </span>
        </li>

        {renderNumbers()}
        <li
          onClick={() => onPageClicked(meta.last_page)}
          className={`page-item ${
            meta.current_page === meta.last_page ? "disabled" : ""
          }`}
        >
          <span className="page-link" aria-label="Next">
            <span aria-hidden="true">&raquo;</span>
          </span>
        </li>
      </ul>
    </nav>
  );
}

export default Pagination;
