import { useEffect } from "react";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "src/store";
import {
  addProcurementTypes,
  deleteProcurementTypes,
  showProcurementTypes,
} from "src/store/vrsta_nabavke-store";
import { updateProcedureTypes } from "src/store/vrsta_postupka-store";
import TableForItems from "src/components/shared/TableForItems";

function Nabavke() {
  const { procurementTypes, loading, CEsuccess } = useAppSelector(
    (state) => state.procurementTypes
  );
  const { user } = useAppSelector((state) => state.login);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (CEsuccess) {
      toast.success("Success");
    }
  }, [CEsuccess, dispatch]);

  useEffect(() => {
    if (user) {
      dispatch(showProcurementTypes());
    }
  }, [dispatch, user]);

  return (
    <>
      {procurementTypes && (
        <TableForItems
          title="Nabavke"
          titleButton="Dodaj  nabavke"
          titleCg="Naziv vrste  nabavke"
          titleEn="Procurement types title"
          deleteFunction={deleteProcurementTypes}
          addFunction={addProcurementTypes}
          editFunction={updateProcedureTypes}
          data={procurementTypes}
        />
      )}
    </>
  );
}

export default Nabavke;
