import { useEffect } from 'react'
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'src/store';
import { addSolutionOptions, deleteSolutionOptions, resetSolutionOptions, showSolutionOptions, updateSolutionOptions } from 'src/store/vrsta_rjesenja-store';
import TableForItems from 'src/components/shared/TableForItems';

function VrstaRjesneja() {
    const { solutionOptions, loading, CEsuccess } = useAppSelector(
        (state) => state.solutionOptions
      );
      const { user } = useAppSelector((state) => state.login);
      const dispatch = useAppDispatch();
    
      useEffect(() => {
        if (CEsuccess) {
          toast.success("Success");
          dispatch(resetSolutionOptions())

        }
      }, [CEsuccess, dispatch]);
    
      useEffect(() => {
        if (user) {
          dispatch(showSolutionOptions());
        }
      }, [dispatch, user]);
    

    
      return (
        <>
        {solutionOptions && (
          <TableForItems
            title="Vrsta rjesenja"
            titleButton="Dodaj vrstu rjesenja"
            titleCg="Naziv vrste rjesneja"
            titleEn="Solution option title"
            deleteFunction={deleteSolutionOptions}
            addFunction={addSolutionOptions}
            editFunction={updateSolutionOptions}
            data={solutionOptions}
          />
        )}
      </>
      )
}

export default VrstaRjesneja