import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "./interceptors/api";
import { Data, Daum, Name, ReturnDataAdd, Types } from "src/components/shared/models";

interface InitialStateProcurementTypes {
  loading: boolean;
  isSuccess: boolean;
  CEsuccess: boolean;
  CEerror: boolean;
  isError: boolean;
  procurementType: ReturnDataAdd | null;
  procurementTypes: Types | null;
}

const initialProcurementTypesState: InitialStateProcurementTypes = {
  loading: false,
  isSuccess: false,
  isError: false,
  procurementTypes: { links: null, meta: null, data: [] },
  CEsuccess: false,
  CEerror: false,
  procurementType: null
};

export const showProcurementTypes = createAsyncThunk(
  "procurementTypes/show",
  async (_, thunkAPI) => {
    try {
      const response = await api.get(`/procurement-types`);
      return response.data as Types;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to fetch issues.");
    }
  }
);


export const addProcurementTypes= createAsyncThunk<ReturnDataAdd, any>(
  "procurement-types/add",
  async (data: Name, thunkAPI) => {
    try {
      const response = await api.post(`/procurement-types`, data);
      return response.data as ReturnDataAdd;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to fetch issues.");
    }
  }
);

export const updateProcurementTypes = createAsyncThunk<ReturnDataAdd, any>(
  "procurement-types/update",
  async (data: Data, thunkAPI) => {
    try {
      const dataToSend = { ...data, _method: "PUT" };

      const response = await api.post(`/procurement-types/${data.id}`, dataToSend);

      return response.data as ReturnDataAdd;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to fetch issues.");
    }
  }
);

export const deleteProcurementTypes = createAsyncThunk<number, any>(
  "procurement-types/delete",
  async (id, thunkAPI) => {
    try {
      const response = await api.delete(`/procurement-types/${id}`);

      return id;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to fetch issues.");
    }
  }
);

const procurementTypesSlice = createSlice({
  name: "procurementTypes",
  initialState: initialProcurementTypesState,
  reducers: {
    resetProcurementTypes(state) {
      state.isSuccess = false;
      state.isError = false;
      state.CEerror = false;
      state.CEsuccess = false;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(showProcurementTypes.pending, (state) => {
        state.loading = true;
      })
      .addCase(showProcurementTypes.fulfilled, (state, action) => {
        state.loading = false;
        state.isSuccess = true;
        state.isError = false;

        state.procurementTypes = action.payload;
      })

      .addCase(showProcurementTypes.rejected, (state) => {
        state.loading = false;
        state.isError = true;
        state.isSuccess = false;
        state.procurementTypes = null;
      })




      .addCase(addProcurementTypes.pending, (state) => {
        state.loading = true;
      })
      .addCase(addProcurementTypes.fulfilled, (state, action) => {
        state.loading = false;
        state.CEsuccess = true;
        state.CEerror = false;

        state.procurementType = action.payload;
        state.procurementTypes = state.procurementTypes &&
          state.procurementTypes.data && {
            ...state.procurementTypes,
            data: [...state.procurementTypes?.data, action.payload.data],
          };
      })

      .addCase(addProcurementTypes.rejected, (state) => {
        state.loading = false;
        state.CEerror = true;
        state.CEsuccess = false;
        state.procurementType = null;
      })


      .addCase(updateProcurementTypes.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateProcurementTypes.fulfilled, (state, action) => {
        state.loading = false;
        state.CEsuccess = true;
        state.CEerror = false;

        state.procurementType = action.payload;

        if (state.procurementType && state.procurementType.data) {
          state.procurementTypes = state.procurementTypes &&
            state.procurementTypes.data && {
              ...state.procurementTypes,
              data: state.procurementTypes?.data.map((type: Daum) => {
                if (type.id === action.payload.data.id) {
                  return action.payload.data;
                }

                return type;
              }),
            };
        }
      })
      .addCase(updateProcurementTypes.rejected, (state) => {
        state.loading = false;
        state.isError = true;
        state.isSuccess = false;
        state.procurementType = null;
      })


      .addCase(deleteProcurementTypes.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteProcurementTypes.fulfilled, (state, action) => {
        state.loading = false;
        state.isSuccess = true;
        state.isError = false;
        state.procurementTypes = state.procurementTypes &&
          state.procurementTypes.data && {
            ...state.procurementTypes,
            data: state.procurementTypes?.data.filter((x) => x.id !== action.payload),
          };
      })
      .addCase(deleteProcurementTypes.rejected, (state) => {
        state.loading = false;
        state.isError = true;
        state.isSuccess = false;
      })
  },
});

export const { resetProcurementTypes } = procurementTypesSlice.actions;
export default procurementTypesSlice.reducer;
