import { useState } from 'react';
import { useDispatch } from 'react-redux';

import {ObjaveIcon, KategorijeIcon, DocumentIcon, UserIcon} from '../../shared/icon/TablerIcon';

import HamMenu from './HamMenu';
// import { authActions } from 'src/store/login-store';
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { userLogout } from 'src/store/login-store';
import { useAppDispatch } from 'src/store';

export const navItems = [
  {
    text: 'Objave',
    href: './objave',
    icon: <ObjaveIcon/>
  },
  {
    text: 'Kategorije',
    href: './kategorije',
    icon: <KategorijeIcon/>
  },
  {
    text: 'Dokumenta',
    href: './dokumenta',
    icon: <DocumentIcon/>
  },
];

const Nav = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useAppDispatch();

  const logoutHabndler = () => {
    dispatch(userLogout());
  }

  return (
    <>
      <header className="page-header page-header-border">
        <div className="container-lg px-3 d-flex flex-row justify-content-between align-items-center">
          <div className='d-flex'>
            <Link to='./'>
            <img className='img-logo-cg' src='/logo-cg.png'/>
            </Link>
            <ul className="d-none d-lg-flex align-items-center m-0 list-unstyled gap-4">
              {navItems.map((prod, index) => (
                <li key={index}>
                  <NavLink className='nav-komisija' to={prod.href} style={({ isActive }) => ({ color: isActive ? 'rgb(90, 90, 90)' : 'unset', textDecoration: 'none', })}>{prod.icon}{prod.text}</NavLink>
                </li>
              ))}
              <li className='d-flex gap-1 align-items-center pe-auto' >
              <div className="dropdown">
              <div className="d-flex dropdown-toggle align-items-center cursor-pointer" data-bs-toggle="dropdown">
              <span>Dodatno</span>

                <div className="d-flex flex-column ">
                  <p className="m-0"></p>
                  <p className="m-0"></p>
                </div>
              </div>
              <div className="dropdown-menu">
                <NavLink className="dropdown-item" to="./tip-rjesenja">
                  Tip rjesenja
                </NavLink>
                <NavLink className="dropdown-item" to="./vrsta-predmeta">
                  Vrsta predmeta
                </NavLink>
                <NavLink className="dropdown-item" to="./vrsta-postupka">
                  Vrsta postupka
                </NavLink>
                <NavLink className="dropdown-item" to="./posebne-javne-nabavke">
                Posebni oblici javne nabavke
                </NavLink>
                {/* <NavLink to="./nabavke" className="dropdown-item" >
                Nabavke
                </NavLink> */}
                {/* <NavLink to="./vrsta-rjesenja" className="dropdown-item">
                Vrsta rješenja
                </NavLink> */}
            </div>
            </div>
              </li>
            </ul>
          </div>

          <div className="d-flex gap-3 align-items-center">
            <div className="dropdown">
              <div className="d-flex dropdown-toggle align-items-center cursor-pointer" data-bs-toggle="dropdown">
              <UserIcon/>

                <div className="d-flex flex-column ">
                  <p className="m-0"></p>
                  <p className="m-0"></p>
                </div>
              </div>
              <div className="dropdown-menu">
                <button onClick={logoutHabndler} className="dropdown-item">
                  Logout
                </button>
              </div>
            </div>

            <button
              onClick={() => setIsOpen((state) => !state)}
              className="d-lg-none d-flex flex-column justify-content-between bg-transparent border-0"
              style={{
                height: 18,
                width: 24,
              }}>
              <span
                className="h-px w-full bg-black d-block"
                style={{
                  transition: '0.4s',
                  transform: isOpen ? 'translateY(8.5px) rotate(45deg)' : '',
                }}></span>
              <span
                className="h-px w-full bg-black d-block"
                style={{
                  transition: '0.3s',
                  transform: isOpen ? 'scaleX(0)' : '',
                }}></span>
              <span
                className="h-px w-full bg-black d-block"
                style={{
                  transition: '0.4s',
                  transform: isOpen ? 'translateY(-8.5px) rotate(-45deg)' : '',
                }}></span>
            </button>
          </div>
        </div>
      </header>

      <HamMenu isOpen={isOpen} closeMenu={() => setIsOpen(false)} />

      
    </>
  );
};

export default Nav;
