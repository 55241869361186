import { useAppDispatch } from 'src/store'

interface Props{
    onClose: () => void,
    id: Number,
    deleteItem: (id: Number) => any
}

function ModalDelete({onClose, id, deleteItem}: Props) {

    const dispatch = useAppDispatch()
    const handleDelete = () => {
     dispatch(deleteItem(id))
    }
  return (
  <div className='delete-modal'>
        <div>
            <h3 className='card-header'>Obriši stavku</h3>

            <h2 className='card-body'>Da li ste sigurni da želite obrisati odabranu stavku?</h2>

            <div className='btn-list flex-nowrap justify-content-center'>
                <button onClick={onClose} className="btn btn-primary-outline">Ne</button>
                <button onClick={handleDelete} className="btn btn-danger">Da</button>
            </div>
        </div>
  </div>
  )
}

export default ModalDelete