import axios from "axios";
import { toast } from "react-toastify";

const currentSession = () => {
  let bearerToken = null;
  const storageUser = localStorage.getItem("user") || null;
  if (storageUser) {
    const initialAuthState = JSON.parse(storageUser);
    bearerToken = initialAuthState.access_token ?? null;
  }
  return bearerToken;
};
const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  timeout: 150000,
});

// Request interceptor for adding the bearer token

api.interceptors.request.use(async (config: any) => {
  try {
    if (config.method !== "get") {
      document.body.classList.add("loading-indicator");
    }
    const jwtToken = currentSession();
    if (jwtToken) {
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${jwtToken}`,
        },
      };
    } else {
      return config;
    }
  } catch (_e) {
    return config;
  }
});

api.interceptors.response.use(
  function (response) {
    document.body.classList.remove("loading-indicator");
    return response;
  },
  function (error) {
    document.body.classList.remove("loading-indicator");
    if (error.response.status === 401) {
      toast.error("Unautorized. Please login again");

      setTimeout(() => {
        localStorage.removeItem("user");
        window.location.reload();
      }, 900);
    } else {
      toast.error("Something went wrong");
    }
    return Promise.reject(error);
  }
);

export default api;
