import { DaumPost } from "src/components/shared/models";
import moment from "moment";
import ActionButtonsObjave from "./ActionButtonsObjave";
import { IMAGE_LINK } from "src/data/image_link";
const ObjavaENGList = (props: any) => {
  return (
    <>
      {props &&
        props.listaOENG &&
        props.listaOENG.map((lista: DaumPost) => (
          <tr key={lista.id}>
            <td className="text-secondary">
              <div className="d-flex align-items-center">
                {lista && lista.image && lista.image.file_url  && (
                  <img
                    style={{ width: 50 }}
                    className="me-2"
                    alt="post image"
                    src={`${IMAGE_LINK}/${lista.image.file_url}`}
                  />
                )}
                <p className="title">
                  {lista.title.en && lista.title.en.length > 50
                    ? lista.title.en.slice(0, 50) + "..."
                    : lista.title.en || "/"}
                </p>
              </div>
            </td>
            <td
              className="list-center"
              dangerouslySetInnerHTML={{
                __html:
                  lista.content.en && lista.content.en.length > 150
                    ? lista.content.en.slice(0, 150) + "..."
                    : lista.content.en || "/",
              }}
            />
            <td>{moment(lista.date_created).format("DD-MM-YYYY")}</td>
            <td>
              <ActionButtonsObjave data={props.data} id={lista.id} />
            </td>
          </tr>
        ))}
    </>
  );
};

export default ObjavaENGList;
