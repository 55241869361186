import { useState } from "react";
import { DeleteIcon, EditIcon } from "../../components/shared/icon/TablerIcon";
import Modal from "src/components/modal/Modal";
import ModalDelete from "src/components/shared/ModalDelete";
import { Link } from "react-router-dom";
import { deletePost } from "src/store/objave-store";

interface ActionButtonProps {
  id: number;
  data: any
}

function ActionButtonsObjave({ id, data }: ActionButtonProps) {
  const [showModalDelete, setShowModalDelete] = useState(false);

  const handleCloseDelete = () => setShowModalDelete(false);
  const handleShowDelete = () => setShowModalDelete(true);

  return (
    <>
      <div className="btn-list flex-nowrap">
        <Link to={`/objave/edit-objava/${id}`} state={{data}} className="btn" title="Izmijeni">
          <EditIcon />
        </Link>
        <button onClick={handleShowDelete} className="btn" title="Izbriši">
          <DeleteIcon />
        </button>
      </div>

      {showModalDelete && (
        <Modal onClose={handleCloseDelete}>
          <ModalDelete deleteItem={deletePost} onClose={handleCloseDelete} id={id} />
        </Modal>
      )}
    </>
  );
}

export default ActionButtonsObjave;
