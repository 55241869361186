import * as React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./style/global.scss";

import Login from "./pages/Login";

import Error404 from "./pages/Error404";
import Objave from "./pages/Objave/Objave";
import NovaObjava from "./pages/Objave/NovaObjava";
import Kategorije from "./pages/Kategorije/Kategorije";
import Dokumenta from "./pages/Dokumenta/Dokumenta";
import DodajDokument from "./pages/Dokumenta/DodajDokuemt";
import Notification from "src/components/UI/Notification";
import RootLayout from "./pages/Root";
import { useAppDispatch, useAppSelector } from "./store";
import EditObjava from "./pages/Objave/EditObjava";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { showSolutionTypes } from "./store/tip_rjesenja-store";
import { showSolutionOptions } from "./store/vrsta_rjesenja-store";
import { showSubjectTypes } from "./store/vrsta_predmeta-store";
import { showSPublicProcurement } from "./store/p_oblici_j_n-store";
import { showProcedureTypes } from "./store/vrsta_postupka-store";
import { showProcurementTypes } from "./store/vrsta_nabavke-store";
import EditDokumenta from "./pages/Dokumenta/EditDokumenta";
import TipRjesenja from "./pages/TipRjesenja/TipRjesenja";
import VrstaPredmeta from "./pages/VrstaPredmeta/VrstaPredmeta";
import VrstaPostupka from "./pages/VrstaPostupka/VrstaPostupka";
import PObliciJavneNabavke from "./pages/PObliciJavneNabavke/PObliciJavneNabavke";
import Nabavke from "./pages/Nabavke/Nabavke";
import VrstaRjesneja from "./pages/VrstaRjesenja/VrstaRjesneja";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <Error404 />,
    children: [
      {
        index: true,
        element: <Objave />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/objave",
        element: <Objave />,
      },
      {
        path: "/objave/nova-objava",
        element: <NovaObjava />,
      },
      {
        path: "/objave/edit-objava/:id",
        element: <EditObjava />,
      },
      {
        path: "/kategorije",
        element: <Kategorije />,
      },
      {
        path: "/dokumenta",
        element: <Dokumenta />,
      },
      {
        path: "/dokumenta/novi-dokument",
        element: <DodajDokument />,
      },
      {
        path: "/dokumenta/edit-dokument/:id",
        element: <EditDokumenta />,
      },
      { 
        path: "/tip-rjesenja", 
        element: <TipRjesenja /> 
      },
      {
        path: "/vrsta-predmeta",
        element: <VrstaPredmeta />
      },
      {
        path: "/vrsta-postupka",
        element: <VrstaPostupka />
      },
      {
        path: "/posebne-javne-nabavke",
        element: <PObliciJavneNabavke />
      },
      {
        path: "/nabavke",
        element: <Nabavke />
      },
      {
        path: "/vrsta-rjesenja",
        element: <VrstaRjesneja />
      }
    ],
  },
]);

function App() {
  const notification = useAppSelector((state: any) => state.ui.notification);
  const isAuth = useAppSelector((state: any) => state.login.isAuthenticated);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (isAuth) {
      dispatch(showSolutionTypes());
      dispatch(showSolutionOptions());
      dispatch(showSubjectTypes());
      dispatch(showSPublicProcurement());
      dispatch(showProcedureTypes());
      dispatch(showProcurementTypes());
    }
  }, [dispatch, isAuth]);

  return (
    <React.Fragment>
      {!isAuth && <Login />}
      {isAuth && (
        <>
          {notification && (
            <Notification
              status={notification.status}
              title={notification.title}
            />
          )}
          <RouterProvider router={router} />
        </>
      )}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
      />
    </React.Fragment>
  );
}

export default App;
