import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "src/store";
import { uploadDocumentsFromFile } from "src/store/dokument-store";

interface Props {
  onClose: (value: boolean) => void;
}

function ImportDocumentsModal({ onClose }: Props) {
  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState<string[]>([]);
  const { loading, CEsuccess } = useAppSelector((state) => state.document);
  const dispatch = useAppDispatch();

  const onFileChange = (e: any) => {
    setFile(e.target.files[0]);
  };

  const uploadFile = () => {
    if (!file) {
      setErrors(["Molimo dodajte dokument"]);
      return;
    }

    setErrors([]);

    const formData = new FormData();

    formData.append('excel_file', file)

    dispatch(uploadDocumentsFromFile(formData));
  };

  useEffect(() => {
    if (CEsuccess) {
      toast.success("Success");

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  }, [CEsuccess]);

  return (
    <>
      <div>
        <div className="card-header mb-3">Dodajte .xlsx dokument</div>
        <div className="card-body">
          <div className="mb-3">
            <label htmlFor="formFile" className="form-label">
              Dodajte dokument
            </label>
            <input
              disabled={loading}
              onChange={(e) => onFileChange(e)}
              accept=".xlsx"
              max={1}
              className="form-control"
              type="file"
              id="formFile"
            />
            {errors &&
              errors.length > 0 &&
              errors.map((item: string, index: number) => (
                <p key={index} className="error-message">{item}</p>
              ))}
          </div>
        </div>
        <div className="btn-list flex-nowrap justify-content-end">
          <button
            disabled={loading}
            className="btn btn-primary-outline"
            onClick={() => onClose(false)}
          >
            Obustavi
          </button>
          <button
            onClick={uploadFile}
            disabled={loading}
            className="btn btn-primary"
            type="button"
          >
            {loading ? "Dodavanje dokumenata..." : "Dodaj"}
          </button>
        </div>
      </div>
    </>
  );
}

export default ImportDocumentsModal;
