import React, { useState } from "react";
import { Categories, Daum, Name } from "./models";
import { DeleteIcon, EditIcon } from "./icon/TablerIcon";
import Modal from "../modal/Modal";
import AddEditModal from "./AddEditModal";
import ModalDelete from "./ModalDelete";

interface ActionButtonProps {
  id: number;
  data?: any;
  editFunction: (data: any) => any;
  deleteFunction: (id: Number) => any;
  categories?: Categories | null;
}
function ActionButtons({
  id,
  data,
  editFunction,
  deleteFunction,
  categories,
}: ActionButtonProps) {
  const [showModal, setShowModal] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const handleCloseDelete = () => setShowModalDelete(false);
  const handleShowDelete = () => setShowModalDelete(true);

  return (
    <>
      <div className="btn-list flex-nowrap">
        <button onClick={handleShow} className="btn" title="Izmijeni">
          <EditIcon />
        </button>
        <button onClick={handleShowDelete} className="btn" title="Izbriši">
          <DeleteIcon />
        </button>
      </div>

      {showModal && (
        <Modal onClose={handleClose}>
          <AddEditModal
            id={id}
            is_edit={true}
            data={data}
            onClose={handleClose}
            titleCG="Naziv tipa rjsenja"
            titleEN="Solution type title"
            editFunction={editFunction}
            categories={categories}
          />
        </Modal>
      )}

      {showModalDelete && (
        <Modal onClose={handleCloseDelete}>
          <ModalDelete
            deleteItem={deleteFunction}
            onClose={handleCloseDelete}
            id={id}
          />
        </Modal>
      )}
    </>
  );
}

export default ActionButtons;
