import { useEffect } from 'react'
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'src/store';
import { addSPublicProcurement, deleteSPublicProcurement, resetSPublicProcurement, showSPublicProcurement, updateSPublicProcurement } from 'src/store/p_oblici_j_n-store';
import TableForItems from 'src/components/shared/TableForItems';

function PObliciJavneNabavke() {
  const { sPublicProcurements, loading, CEsuccess } = useAppSelector(
      (state) => state.sPublicProcurement
    );
    const { user } = useAppSelector((state) => state.login);
    const dispatch = useAppDispatch();
  
    useEffect(() => {
      if (CEsuccess) {
        toast.success("Success");
        dispatch(resetSPublicProcurement())
      }
    }, [CEsuccess, dispatch]);
  
    useEffect(() => {
      if (user) {
        dispatch(showSPublicProcurement());
      }
    }, [dispatch, user]);
  
  
    return (
      <>
      {sPublicProcurements && (
        <TableForItems title="Posebni oblici javne nabavke"
          titleButton="Dodaj vrstu posebne nabavke"
          titleCg="Naziv vrste posbne nabavke"
          titleEn="Special procurement types title"
          deleteFunction={deleteSPublicProcurement}
          addFunction={addSPublicProcurement}
          editFunction={updateSPublicProcurement}
          data={sPublicProcurements}
        />
      )}
    </>
    )
}

export default PObliciJavneNabavke