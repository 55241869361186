const Wrapper = (props: React.HTMLProps<HTMLDivElement>) => {
  return (
    <>
      <div className="page-body">
        <div className="container-xl">{props.children}</div>
      </div>
    </>
  );
};

export default Wrapper;
