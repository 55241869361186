import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store';
// import { authActions } from '../store/login-store'
import { userLogin } from 'src/store/login-store';

function Login() {
  const dispatch = useAppDispatch();
  const {isAuthenticated} = useAppSelector(state => state.login)
  const [enteredName, setEnteredName] = useState('');
  const [enteredPassowrd, setEnteredPassowrd] = useState('');
  const [enteredTouched, setEnteredTouched] = useState(false);

  const enteredNameIsValid = enteredName.trim() !== '';
  const nameInputIsInvalid = !enteredNameIsValid && enteredTouched;

  const enteredPassowrdIsValid = enteredPassowrd.trim().length >= 6;
  const passwordInputIsInValid = !enteredPassowrdIsValid && enteredTouched;

  const nameInputChangeHandler = (event: any) => {
    setEnteredName(event.target.value);
  };

  const passowordInputChangeHandler = (event: any) => {
    setEnteredPassowrd(event.target.value);
  };

  const nameInputBlur = (event: any) => {
    setEnteredTouched(true);
  };

  const handlerSubmit = (event: any) => {
    event.preventDefault();
    setEnteredTouched(true);
    if (!enteredNameIsValid) {
      return;
    }
    setEnteredName('');
    if (!enteredPassowrdIsValid) {
      return;
    }
    setEnteredPassowrd('');
    setEnteredTouched(false);
    dispatch(userLogin({email: enteredName, password: enteredPassowrd}));
  };

  const buttonClasses = nameInputIsInvalid || passwordInputIsInValid ? 'btn-primary disabled' : 'btn-primary';

  useEffect(() => {
      if(isAuthenticated){
        // window.location.pathname = "/"
      }
  },[isAuthenticated])

  return (
    <div className="container container-tight py-4">
      <div className="text-center mb-4">
      <img className='img-logo-cg-login' src='/logo-cg.png'/>
        <h2>Komisija za zaštitu prava u postupcima javnih nabavki</h2>
      </div>
      <div className="card card-md">
        <form onSubmit={handlerSubmit} method="post">
          <input type="hidden" name="_token" />
          <div className="card-body">
            <h2 className="h2 text-center mb-4">Prijavite se na Vaš nalog</h2>
            <div className="mb-3">
              <label className="form-label">Email adresa</label>
              <input
                type="email"
                name="email"
                className="form-control"
                placeholder="E-mail"
                onBlur={nameInputBlur}
                value={enteredName}
                onChange={nameInputChangeHandler}
              />
              {nameInputIsInvalid && <div className="error-message">Email in not valid</div>}
            </div>
            <div className="mb-2">
              <label className="form-label">
                Lozinka
                <span className="form-label-description"></span>
              </label>
              <div className="input-group input-group-flat">
                <input
                  type="password"
                  name="password"
                  className="form-control"
                  placeholder="Lozinka"
                  onBlur={nameInputBlur}
                  value={enteredPassowrd}
                  onChange={passowordInputChangeHandler}
                />
              </div>
              {passwordInputIsInValid && <div className="error-message">Minimum 6 character</div>}
            </div>
            <div className="form-footer">
              <button type="submit" className={`btn w-100 ${buttonClasses}`}>
                Prijavi se
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
