import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/store";
import {
  addCategory,
  deleteCategory,
  resetCategory,
  showCategories,
  updateCategory,
} from "src/store/kategorija-store";
import { toast } from "react-toastify";
import TableForItems from "src/components/shared/TableForItems";
import Pagination from "src/components/shared/Pagination";
import { useSearchParams } from "react-router-dom";

const Kategorije = () => {
  const { categories, loading, CEsuccess } = useAppSelector(
    (state) => state.category
  );
  const { user } = useAppSelector((state) => state.login);
  const dispatch = useAppDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const [page, setPage] = useState(parseInt(searchParams.get("page") || "1"));
  const [search, setSearch] = useState(searchParams.get("search[title]") || "");
  let data: any = {
    page,
    "search[title]": search,
    per_page: 10,
  };

  useEffect(() => {
    if (CEsuccess) {
      toast.success("Success");
      dispatch(resetCategory());
    }
  }, [CEsuccess, dispatch]);

  useEffect(() => {
      dispatch(showCategories(data));
    
  }, [dispatch]);

  return (
    <>
      {categories && (
        <TableForItems
          title="Kategorije"
          titleButton="Dodaj  kategoriju"
          titleCg="Naziv kategorije"
          titleEn="Category title"
          deleteFunction={deleteCategory}
          addFunction={addCategory}
          editFunction={updateCategory}
          data={categories}
          categories={categories}
        />
      )}

      {categories?.meta && (
        <Pagination
          meta={categories?.meta}
          setPage={setPage}
          data={data}
          switchFunction={showCategories}
          path="kategorije"
        />
      )}
    </>
  );
};

export default Kategorije;
