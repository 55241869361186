export const vrsteRjesenja = [
  {
    title: "Rješenje kojim se odbija žalba",
    children: [
      "Odbijena žalba kao neosnovana",
      "Odbijena žalba kao nepotpuna",
      "Odbijena žalba kao neblagovremena",
      "Odbijena žalba kao nedozvoljena",
      "Odbijena žalba kao izjavljena od strane neovlašćenog lica",
      "Odbijena žalba kao neuredna",
    ],
  },
  {
    title: "Rješenje kojim se obustavlja postupak",
    children: ["Postupak obustavljen usled odustanka žalioca od žalbe"],
  },
  {
    title: "Rješenje kojim se usvaja žalba",
    children: [
      "Usvojena žalba u cjelosti",
      "Usvojena žalba djelimično",
      "Po službenoj dužnosti poništen postupak javnih nabavki u cjelosti",
      "Po službenoj dužnosti poništen postupak javnih nabavki djelimično",
    ],
  },
  {
    title: "Ostalo",
    children: [
      "Odbijena žalba zbog nenadležnosti",
      "Ispravka rješenja",
      "Dopunsko rješenje",
      "Rješenje"
    ],
  },
];
