import React from 'react'
import { IconX } from '@tabler/icons-react'

import { navItems } from './Nav'
import useWindowWidth from 'src/hooks/useWindowWidth'
import { NavLink } from 'react-router-dom'

type Props = {
  isOpen: boolean
  closeMenu: () => void
}

const HamMenu = ({ isOpen, closeMenu }: Props) => {
  const width = useWindowWidth()

  return (
    <div
      className="position-fixed z-1 bg-white"
      style={{
        height: '100vh',
        width: width < 640 ? '100%' : '350px',
        borderLeft: '1px solid black',
        top: '0',
        right: '0',
        transition: 'all 0.6s',
        transform: isOpen ? '' : 'translateX(100%)'
      }}
    >
      <div
        className="d-flex align-items-center justify-content-end px-2 border-bottom"
        style={{ height: '81px' }}
      >
        <IconX
          size={33}
          stroke={2}
          color="black"
          onClick={closeMenu}
          className="cursor-pointer"
        />
      </div>

      <ul
        className="list-unstyled m-0 d-flex flex-column align-items-center gap-4 p-4"
        style={{
          fontSize: '18px'
        }}
      >
        {navItems.map(({ text, href }, index) => (
          <li key={index}>
            <a href={href}>{text}</a>
          </li>
        ))}

<li className='d-flex gap-1 align-items-center pe-auto' >
                <span>Dodatno</span>
              <div className="dropdown">
              <div className="d-flex dropdown-toggle" data-bs-toggle="dropdown">
                <div className="d-flex flex-column ">
                  <p className="m-0"></p>
                  <p className="m-0"></p>
                </div>
              </div>
              <div className="dropdown-menu">
                <NavLink className="dropdown-item" to="./tip-rjesenja">
                  Tip rjesenja
                </NavLink>
                <NavLink className="dropdown-item" to="./vrsta-predmeta">
                  Vrsta predmeta
                </NavLink>
                <NavLink className="dropdown-item" to="./vrsta-postupka">
                  Vrsta postupka
                </NavLink>
                <NavLink className="dropdown-item" to="./posebne-javne-nabavke">
                Posebni oblici javne nabavke
                </NavLink>
                <NavLink to="./nabavke" className="dropdown-item" >
                Nabavke
                </NavLink>
                <NavLink to="./vrsta-rjesenja" className="dropdown-item">
                Vrsta rješenja
                </NavLink>
            </div>
            </div>
              </li>
      </ul>
    </div>
  )
}

export default HamMenu
