import React, { useEffect, useState } from "react";
import { PlusIcon } from "./icon/TablerIcon";
import ListForItem from "./ListForItem";
import Modal from "../modal/Modal";
import AddEditModal from "./AddEditModal";
import { Categories } from "./models";
import { useAppDispatch, useAppSelector } from "src/store";
import {
  showCategories,
  showCategoriesForAdd,
} from "src/store/kategorija-store";

interface Props {
  title: string;
  titleButton: string;
  titleEn: string;
  titleCg: string;
  editFunction: (data: any) => any;
  deleteFunction: (id: Number) => any;
  data: any;
  addFunction: (data: any) => any;
  categories?: Categories;
}

function TableForItems({
  title,
  titleButton,
  titleCg,
  titleEn,
  editFunction,
  deleteFunction,
  data,
  addFunction,
  categories,
}: Props) {
  const [language, setLanguage] = useState("cg");
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const dispatch = useAppDispatch();

  const { addCategories: categoriesFromApi } = useAppSelector(
    (state) => state.category
  );

  useEffect(() => {
    if (categories) {
      dispatch(showCategoriesForAdd({ per_page: 100 }));
    }
  }, []);
  return (
    <div className="kategorije">
      <div className="title-first row g-2 align-items-center">
        <div className="col">
          <h2 className="page-title">{title}</h2>
        </div>
        <div className="col-auto ms-auto d-print-none">
          <div className="btn-list">
            <a
              onClick={handleShow}
              className="btn btn-primary d-sm-inline-block"
            >
              <PlusIcon />
              {titleButton}
            </a>
          </div>
        </div>
      </div>

      {data && (
        <div className="card">
          <div className="card-header">
            <ul className="nav nav-tabs card-header-tabs">
              <li
                onClick={() => setLanguage("cg")}
                role="presentation"
                className="active"
              >
                <a
                  href="#tab-1"
                  className="nav-link active"
                  aria-controls="#tab-1"
                  role="tab"
                  data-bs-toggle="tab"
                  aria-selected="true"
                >
                  CNR
                </a>
              </li>
              <li onClick={() => setLanguage("en")} role="presentation">
                <a
                  href="#tab-2"
                  className="nav-link"
                  aria-controls="#tab-2"
                  role="tab"
                  data-bs-toggle="tab"
                  aria-selected="false"
                >
                  ENG
                </a>
              </li>
            </ul>
          </div>
          <div className="card-table table-responsive">
            <div className="tab-content">
              <div className="tab-pane active">
                <table className="table table-vcenter">
                  <thead>
                    <tr>
                      <th>{language === "cg" ? titleCg : titleEn}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <ListForItem
                      categories={categories ? categoriesFromApi : null}
                      editFunction={editFunction}
                      deleteFunction={deleteFunction}
                      lista={data.data}
                      language={language}
                    />
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}

      {showModal && (
        <Modal onClose={handleClose}>
          <AddEditModal
            categories={categories ? categoriesFromApi : null}
            titleCG={titleCg}
            titleEN={titleEn}
            addFunction={addFunction}
            onClose={handleClose}
          />
        </Modal>
      )}
    </div>
  );
}

export default TableForItems;
