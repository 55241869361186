import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/store";
import DokumentForm from "./DokumentForm";
import { getDocumentById } from "src/store/dokument-store";

function EditDokumenta() {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { document } = useAppSelector((state) => state.document);

  useEffect(() => {
    if (id) {
      dispatch(getDocumentById(id));
    }
  }, [id, dispatch]);

  return (
    <>
      {document && id === document.data.id.toString() && (
        <DokumentForm is_edit={true} id={id} data={document} />
      )}
    </>
  );
}

export default EditDokumenta;
