import { useEffect, useState } from "react";
import { PlusIcon } from "src/components/shared/icon/TablerIcon";
import DokumentaCNRList from "./DokumentaCNRList";
import DokumentaENGList from "./DokumentaENGList";
import { Link, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/store";
import { resetDocuments, showDocuments } from "src/store/dokument-store";
import Pagination from "src/components/shared/Pagination";
import ImportDocumentsModal from "src/components/shared/ImportDocumentsModal";
import Modal from "src/components/modal/Modal";

const Dokumenta = () => {
  const dispatch = useAppDispatch();
  const { documents, loading } = useAppSelector((state) => state.document);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showModal, setShowModal] = useState(false)

  const [page, setPage] = useState(parseInt(searchParams.get("page") || "1"));

  let params: any = {
    page,
    per_page: 10,
  };

  useEffect(() => {
    dispatch(showDocuments(params));
    dispatch(resetDocuments());
  }, [dispatch]);

  return (
    <div className="objave">
      <div className="title-first row g-2 align-items-center">
        <div className="col">
          <h2 className="page-title">Dokumenta</h2>
        </div>
        <div className="col-auto ms-auto d-print-none">
          <div className="btn-list">
            <button onClick={() => setShowModal(true)} className="btn btn-primary d-sm-inline-block">
              Importuj dokumente
            </button>

            <Link
              to="/dokumenta/novi-dokument"
              className="btn btn-primary d-sm-inline-block"
            >
              <PlusIcon />
              Dodaj Dokument
            </Link>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-header">
          <ul className="nav nav-tabs card-header-tabs" role="tablist">
            <li role="presentation" className="active">
              <a
                href="#tab-1"
                className="nav-link active"
                aria-controls="#tab-1"
                role="tab"
                data-bs-toggle="tab"
                aria-selected="true"
              >
                CNR
              </a>
            </li>
            <li role="presentation">
              <a
                href="#tab-2"
                className="nav-link"
                aria-controls="#tab-2"
                role="tab"
                data-bs-toggle="tab"
                aria-selected="false"
              >
                ENG
              </a>
            </li>
          </ul>
        </div>
        <div className="card-table table-responsive">
          <div className="tab-content">
            <div role="tabpanel" className="tab-pane active" id="tab-1">
              <table className="table table-vcenter">
                <thead>
                  <tr>
                    <th>Broj rješenja</th>
                    <th>Opis</th>
                    <th>Datum odluke</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {documents && <DokumentaCNRList listaDCNR={documents.data} />}
                  {loading && <p className="m-3">Loading...</p>}
                </tbody>
              </table>
            </div>
            <div role="tabpanel" className="tab-pane" id="tab-2">
              <table className="table table-vcenter">
                <thead>
                  <tr>
                    <th>Decision number</th>
                    <th>Description</th>
                    <th>Date of decision</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {documents && <DokumentaENGList listaDENG={documents.data} />}
                  {loading && <p className="m-3">Loading...</p>}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {documents?.meta && (
          <Pagination
            meta={documents?.meta}
            setPage={setPage}
            data={params}
            switchFunction={showDocuments}
            path="dokumenta"
          />
        )}
      </div>


      {showModal && <Modal onClose={() => setShowModal(false)}>  <ImportDocumentsModal onClose={setShowModal} /> </Modal>}
    </div>
  );
};

export default Dokumenta;
