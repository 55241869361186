import { Outlet } from 'react-router-dom';
import Wrapper from 'src/components/layout/Wrapper';
import Nav from 'src/components/layout/nav/Nav';

function RootLayout() {
  return (
    <>
      <Nav />
      <Wrapper>
        <Outlet />
      </Wrapper>
    </>
  );
}

export default RootLayout;
