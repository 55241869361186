import React from "react";
import { useState, useEffect } from "react";
import MyDropzoneComponent from "../../components/modal/DropZone";
import TinyMCEEditor from "../../components/modal/TinyMce";
import { useAppDispatch, useAppSelector } from "src/store";
import { showCategories } from "src/store/kategorija-store";
import { Data, Post } from "src/components/shared/models";
import moment from "moment";
import {
  addPost,
  removePostCategory,
  resetPosts,
  updatePost,
} from "src/store/objave-store";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface Props {
  data?: Post;
  id?: string;
  is_edit: boolean;
  searchData: any;
}

function ObjaveForm({ is_edit, data, id, searchData }: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { categories } = useAppSelector((state) => state.category);
  const { CEsuccess, loading } = useAppSelector((state) => state.post);

  const [images, setImages] = useState<any>(data ? data.data.images : []);
  const [documentsCG, setDocumentsCG] = useState<any>(
    data ? data.data.documents.filter((x: any) => x.language === "cg") : []
  );

  const [documentsEN, setDocumentsEN] = useState<any>(
    data ? data.data.documents.filter((x: any) => x.language === "en") : []
  );
  const [thumbnail, setThumbnail] = useState<any>(
    data ? [data.data.image] : []
  );
  const [categoriesCheck, setCategoriesCheck] = useState<any[]>(
    data && data.data
      ? data?.data.categories.map((category) => {
          return category.id.toString();
        })
      : []
  );

  const formik = useFormik({
    initialValues: {
      contentCG: data ? data.data.content.cg : "",
      contentEN: data ? data.data.content.en : "",
      titleCG: data ? data.data.title.cg : "",
      titleEN: data ? data.data.title.en : "",
      date_created: data ? new Date(data.data.date_created) : new Date(),
    },
    onSubmit: (values: any) => {
      const formData = new FormData();

      if (values.titleCG) {
        formData.append("title[cg]", values.titleCG);
      }

      if (values.titleEN) {
        formData.append("title[en]", values.titleEN ? values.titleEN : "");
      }

      if (values.contentCG) {
        formData.append("content[cg]", values.contentCG);
      }

      if (values.contentEN) {
        formData.append(
          "content[en]",
          values.contentEN ? values.contentEN : ""
        );
      }
      images !== data?.data.images &&
        images.forEach((image: any) => {
          formData.append("images[]", image);
        });

      documentsCG !==
        data?.data.documents.filter((x: any) => x.language === "cg") &&
        documentsCG.forEach((document: any, index: number) => {
          if (document && !document.id) {
            formData.append(`documents[${index}][path]`, document);
            formData.append(`documents[${index}][lang]`, "cg");
            formData.append(`documents[${index}][order]`, document.order ? document.order : index + 1);
            formData.append(
              `documents[${index}][display_name]`,
              document.display_name || document.name
            );
          }
        });
      documentsEN !==
        data?.data.documents.filter((x: any) => x.language === "en") &&
        documentsEN.forEach((document: any, index: number) => {
          if (document && !document.id) {
            formData.append(`documents[${index}][path]`, document);
            formData.append(`documents[${index}][lang]`, "en");
            formData.append(`documents[${index}][order]`, document.order ? document.order : index + 1);

            formData.append(
              `documents[${index}][display_name]`,
              document.display_name || document.name
            );
          }
        });

      categoriesCheck.forEach((category) => {
        const findCategory =
          data && data.data.categories.find((x: any) => +x.id === +category);
        if (!findCategory) {
          formData.append("categories[]", category);
        }
      });

      if (thumbnail && thumbnail[0] && thumbnail[0] !== data?.data.image) {
        formData.append("image", thumbnail[0]);
      }

      const dateToSend = moment(values.date_created).format("YYYY-MM-DD HH:mm:ss");
      formData.append("date_created", dateToSend);

      if (is_edit) {
        formData.append("_method", "PUT");
        dispatch(updatePost({ formData, id }));
      } else {
        dispatch(addPost(formData));
      }
    
    },
    validationSchema: Yup.object({
      contentCG: Yup.string().required(),
      titleCG: Yup.string().required(),
      date_created: Yup.string().required(),
      titleEN: Yup.string(),
      contentEN: Yup.string(),
    }),
  });

  const handleCheckbox = (e: any) => {
    const { value, checked } = e.target;

    if (checked) {
      setCategoriesCheck([...categoriesCheck, value]);
    } else {
      if (id) {
        dispatch(removePostCategory({ postId: id, categoryId: value }));
      }
      setCategoriesCheck(categoriesCheck.filter((x) => x !== value));
    }
  };

  useEffect(() => {
    if (CEsuccess) {
      toast.success("Success");
      navigate(
        `/objave?page=${searchData.page}&search[title]=${
          searchData["search[title]"] || ""
        }`
      );
    }
  }, [CEsuccess, dispatch, navigate]);

  useEffect(() => {
    dispatch(showCategories({ per_page: 30 }));
    dispatch(resetPosts());
  }, [dispatch]);

  return (
    <div className="novaobjava">
      <div className="title-first row g-2 align-items-center">
        <div className="col">
          <h2 className="page-title">Nova objava</h2>
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="row row-cards">
          <div className="col-md-8">
            <div className="card">
              <div className="card-header">
                <ul className="nav nav-tabs card-header-tabs" role="tablist">
                  <li role="presentation" className="active">
                    <a
                      href="#tab-1"
                      className="nav-link active"
                      aria-controls="#tab-1"
                      role="tab"
                      data-bs-toggle="tab"
                      aria-selected="true"
                    >
                      CNR
                    </a>
                  </li>
                  <li>
                    <a
                      href="#tab-2"
                      className="nav-link"
                      aria-controls="#tab-2"
                      role="tab"
                      data-bs-toggle="tab"
                      aria-selected="false"
                    >
                      ENG
                    </a>
                  </li>
                </ul>
              </div>
              <div className="card-body">
                <div className="tab-content">
                  <div role="tabpanel" className="tab-pane active" id="tab-1">
                    <div className="mb-3">
                      <label className="form-label">
                        Naslov <span className="form-required-filed">*</span>{" "}
                      </label>
                      <input
                        type="text"
                        name="titleCG"
                        className="form-control"
                        placeholder="Naslov objave"
                        value={formik.values.titleCG}
                        onChange={formik.handleChange}
                      />
                      {formik.touched.titleCG && formik.errors.titleCG && (
                        <div className="form-error-message">
                          Naslov je obavezno polje
                        </div>
                      )}
                    </div>
                    <label className="form-label">
                      Sadržaj <span className="form-required-filed">*</span>{" "}
                    </label>
                    <div>
                      <TinyMCEEditor
                        value={formik.values.contentCG}
                        name="contentCG"
                        formik={formik}
                      />
                      {formik.touched.titleCG && formik.errors.titleCG && (
                        <div className="form-error-message">
                          Sadržaj je obavezno polje
                        </div>
                      )}
                    </div>
                  </div>

                  <div role="tabpanel" className="tab-pane" id="tab-2">
                    <div className="mb-3">
                      <label className="form-label">Title</label>
                      <input
                        type="text"
                        name="titleEN"
                        className="form-control"
                        placeholder="Title post"
                        value={formik.values.titleEN}
                        onChange={formik.handleChange}
                      />
                    </div>
                    <label className="form-label">Content</label>
                    <div>
                      <TinyMCEEditor
                        value={formik.values.contentEN}
                        name="contentEN"
                        formik={formik}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="card">
                <div className="card-body">
                  <h3 className="card-title">Fotografije</h3>
                  <MyDropzoneComponent
                    setValue={setImages}
                    type="images"
                    values={images}
                    id={id}
                    buttonText="Izaberite fotografije"
                    accept={{ "image/png": [".png", ".jpeg", ".jpg"] }}
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="card">
                <div className="card-header">
                  <ul className="nav nav-tabs card-header-tabs" role="tablist">
                    <li role="presentation" className="active">
                      <a
                        href="#documents-cg"
                        className="nav-link active"
                        aria-controls="#documents-cg"
                        role="tab"
                        data-bs-toggle="tab"
                        aria-selected="true"
                      >
                        CNR
                      </a>
                    </li>
                    <li>
                      <a
                        href="#documents-en"
                        className="nav-link"
                        aria-controls="#documents-en"
                        role="tab"
                        data-bs-toggle="tab"
                        aria-selected="false"
                      >
                        ENG
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="card-body">
                  <div className="tab-content">
                    <div
                      role="tabpanel"
                      className="tab-pane active"
                      id="documents-cg"
                    >
                      <h3 className="card-title">Dokumenta</h3>
                      <MyDropzoneComponent
                        setValue={setDocumentsCG}
                        // saveForm={handleSave}
                        values={documentsCG}
                        type="documents"
                        id={id}
                        buttonText="Izaberite dokumenta"
                        is_edit={is_edit}
                        single={false}
                      />
                    </div>

                    <div role="tabpanel" className="tab-pane" id="documents-en">
                      <h3 className="card-title">Documents</h3>
                      <MyDropzoneComponent
                        setValue={setDocumentsEN}
                        values={documentsEN}
                        //saveForm={handleSave}
                        type="documents"
                        id={id}
                        buttonText="Choose documents"
                        is_edit={is_edit}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <div className="card-body">
                <h3 className="card-title">Datum objave</h3>
                <div className="input-icon mb-3">
                  <DatePicker
                    showIcon
                    selected={formik.values.date_created}
                    onChange={(date: any) =>
                      formik.setFieldValue("date_created", date)
                    }
                    showTimeInput
                    className="form-control w-100"
                  />
                </div>
                <h3 className="card-title">Kategorija</h3>
                <div className="mb-3">
                  <div>
                    {categories &&
                      categories.data &&
                      categories.data.map((category: Data) => (
                        <label key={category.id} className="form-check">
                          <input
                            className="form-check-input"
                            checked={
                              categoriesCheck.find(
                                (x) => x === category.id.toString()
                              )
                                ? true
                                : false
                            }
                            onChange={handleCheckbox}
                            type="checkbox"
                            value={category.id}
                          />
                          <span className="form-check-label">
                            {category.name.cg}
                          </span>
                        </label>
                      ))}
                  </div>
                </div>
                <h3 className="card-title">Naslovna fotografija</h3>
                <MyDropzoneComponent
                  setValue={setThumbnail}
                  values={thumbnail}
                  id={id}
                  type="thumb"
                  buttonText="Izaberite naslovnu fotografiju"
                  accept={{ "image/png": [".png", ".jpeg", ".jpg"] }}
                  single={true}
                />
              </div>
              <div className="text-end card-footer">
                <button
                  disabled={loading}
                  type="submit"
                  className="btn btn-primary"
                >
                  {loading ? "U obradi..." : "Sačuvaj"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ObjaveForm;
