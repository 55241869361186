import moment from "moment";
import ActionButtonDokumenti from "./ActionButtonDokumenti";

const DokumentaENGList = (props: any) => {
  return (
    <>
      {props.listaDENG.map((lista: any) => (
        <tr key={lista.id}>
          <td>{lista.decision_number}</td>
          <td className="list-center w-100">{lista.content.en && lista.content.en.length > 150 ? lista.content.en.slice(0,150) + '...' : lista.content.en}</td>
          <td>{moment(lista.date_of_decision).format('DD-MM-YYYY')}</td>
          <td>
            <ActionButtonDokumenti id={lista.id} />
          </td>
        </tr>
      ))}
    </>
  );
};

export default DokumentaENGList;
