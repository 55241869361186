import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import "./DropZone.scss";
import { useAppDispatch } from "src/store";
import { deletePostFile } from "src/store/objave-store";
import { deleteDocumentFile, updateDocument } from "src/store/dokument-store";
import { IMAGE_LINK } from "src/data/image_link";
import Modal from "./Modal";
import api from "src/store/interceptors/api";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DragAndDropIcon from "../../assets/drag_and_drop.png";
import { object } from "yup";

interface MyDropzoneProps {
  buttonText: string;
  setValue: (files: any) => void;
  values: any[];
  type: string;
  id?: string;
  is_edit?: boolean;
  single?: boolean;
  accept?: any;
}

function MyDropzoneComponent({
  buttonText,
  setValue,
  values,
  type,
  id,
  is_edit,
  single,
  accept = {},
}: MyDropzoneProps) {
  const dispatch = useAppDispatch();
  const [isInitialSorted, setIsInitialSorted] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [dataToRemove, setDataToRemove] = useState<any>(null);
  const [showRenameForm, setshowRenameForm] = useState(false);
  const [renameFile, setRenameFile] = useState<any>(null);
  const [fileName, setFileName] = useState("");
  const [files, setFiles] = useState(values ? values : []);
  const [sortedFiles, setSortedFiles] = useState(values ? values : []);

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      const test: any[] = [];
      values.map((x: any) => {
        if (x && !x.file_url) {
          test.push(x);
        }
      });

      setValue([...test, ...acceptedFiles]);

      if (type !== "thumb" && !single) {
        setFiles([
          ...files,
          ...acceptedFiles.map((file: any) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
              order: null,
            })
          ),
        ]);
      } else {
        setFiles(
          acceptedFiles.map((file: any) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
              order: null,
            })
          )
        );
      }
    },
    [files, values]
  );
  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    multiple: single ? false : true,
    accept,
  });

  const handleDropzoneClick = () => {
    open();
  };

  const handleClikRename = (file: any, index: number) => {
    setshowRenameForm(true);

    setRenameFile(
      file.id
        ? (file = { ...file, index, display_name: "" })
        : Object.assign(file, {
            index,
            display_name: "",
          })
    );
  };

  const handleRename = () => {
    if (renameFile && fileName) {
      let renamedFiles = files.map((x, i) => {
        if (i === renameFile.index) {
          return renameFile.id
            ? new File(
                [
                  Object.assign(renameFile, {
                    display_name: fileName,
                  }),
                ],
                fileName
              )
            : Object.assign(renameFile, {
                display_name: fileName,
              });
        } else {
          return x;
        }
      });

      if (renameFile && renameFile.id) deleteFile(renameFile.id, true);

      setValue(renamedFiles);
      setFiles(renamedFiles);
      setshowRenameForm(false);
      setFileName("");
    }
  };

  const getUrl = (file: any) => {
    if (file && file.file_url) {
      if (type === "images") {
        return file.file_url;
      }

      if (type === "thumb") {
        return `${IMAGE_LINK}/${file.file_url}`;
      }
    } else if (file && file.preview) {
      return file.preview;
    }
  };

  const deleteFile = (fileId: string, is_rename: boolean) => {
    if (type === "images" || type === "documents" || type === "thumb") {
      dispatch(deletePostFile({ type, postId: id, fileId })).then(
        (res: any) => {
          if (!is_rename) {
            let filestest = files.filter((file) => {
              if (file && file.id && file.id === fileId) {
              } else {
                return file;
              }
            });
            setFiles(filestest);
          }
        }
      );
    } else {
      dispatch(deleteDocumentFile({ type, postId: id, fileId })).then(
        (res: any) => {
          if (!is_rename) {
            let filestest = files.filter((file) => {
              if (file && file.id && file.id === fileId) {
              } else {
                return file;
              }
            });

            setFiles(filestest);
          }
        }
      );
    }
  };

  const handleOpenModal = (data: any) => {
    setShowModalDelete(true);
    setDataToRemove(data);
  };

  const handleDelete = () => {
    if (dataToRemove && dataToRemove.id) {
      deleteFile(dataToRemove.id, false);
      setDataToRemove(null);
      setShowModalDelete(false);
      return;
    }

    if (typeof dataToRemove === "number") {
      removeFromArray(dataToRemove);
      setDataToRemove(null);
      setShowModalDelete(false);
      return;
    }
  };

  const removeFromArray = (index: number) => {
    setFiles(files.filter((file, i) => file && i !== index));
    setValue(files.filter((file, i) => file && i !== index));
  };

  const reorder = (
    list: any[],
    startIndex: number,
    endIndex: number
  ): any[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = async (result: any) => {
    if (!result.destination) {
      return;
    }

    const reorderedItems = reorder(
      sortedFiles,
      result.source.index,
      result.destination.index
    );

    const file = reorderedItems.find(
      (x: any, index: number) => index === result.destination.index
    );

    setSortedFiles(reorderedItems);
    setFiles(reorderedItems);
    setValue(reorderedItems);
    const formData = new FormData();
    formData.append("_method", "PUT");

    const filesReorder = reorderedItems.filter(
      async (item: any, index: number) => {
        if (item && item.id) {
          await api.post(`/order-file/${item.id}/${index + 1}`, formData);
          return item;
        }

        if (item && !item.id) {
         const itemi = Object.assign(item, {order:index+1});
          return itemi;
        }
      }
    );
    setSortedFiles(filesReorder);
    setValue(filesReorder);
    setFiles(filesReorder);
  };

  const dropzoneStyles: React.CSSProperties = {
    border: "2px solid rgb(239, 239, 239)",
    padding: "20px",
    textAlign: "center",
    backgroundColor: "rgb(239, 239, 239)",
    cursor: "pointer",
    borderRadius: "10px",
  };

  const spanstyle = {
    color: "rgb(86, 86, 86)",
  };

  useEffect(() => {
    setValue([]);
  }, []);

  useEffect(() => {
    if (!isInitialSorted && (type == "documentss" || type === "documents")) {
      const sorted = [...files.sort((a, b) => a.order - b.order)];

      if (sorted && sorted.length > 0) {
        setSortedFiles(sorted);
        setIsInitialSorted(true);
      }
    } else {
      const sorted = [...files];
      setSortedFiles(sorted);
    }
  }, [files]);

  return (
    <>
      <div>
        {buttonText}
        <div
          className="dropzone dz-clickable mt-3"
          onClick={handleDropzoneClick}
          style={dropzoneStyles}
        >
          <div className="dz-default dz-message">
            <input {...getInputProps()} />
            <div className="dz-button">
              <span style={spanstyle}>
                Drag 'n' drop some files here, or click to select files
              </span>
            </div>
          </div>
        </div>
        <aside className="thumbsContainer">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable
              isDropDisabled={type !== "documentss" && type !== "documents"}
              droppableId="droppable"
            >
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {sortedFiles.map((file, index) => (
                    <Draggable
                      isDragDisabled={
                        type !== "documentss" && type !== "documents"
                      }
                      key={file && file.id ? file.id : index}
                      draggableId={
                        file && file.id ? file.id.toString() : index.toString()
                      }
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <>
                            {file && (
                              <div
                                className={`${
                                  type.includes("documents") ||
                                  type.includes("single")
                                    ? "document"
                                    : "thumb"
                                }`}
                                key={index}
                              >
                                <div className="thumbInner">
                                  {type === "documents" ||
                                  type === "documentss" ||
                                  type === "single" ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: "row",
                                        gap: 5,
                                      }}
                                    >
                                      {(type === "documentss" ||
                                        type === "documents") && (
                                        <img width={17} src={DragAndDropIcon} />
                                      )}
                                      <p
                                        style={{
                                          fontSize: 13,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Document:{" "}
                                        {(file &&
                                          file.display_name &&
                                          file.display_name) ||
                                          (file &&
                                            file.file_name &&
                                            file.file_name) ||
                                          (file &&
                                            file.original_name &&
                                            file.original_name) ||
                                          (file && file.name && file.name)}
                                      </p>
                                    </div>
                                  ) : (
                                    <img
                                      src={getUrl(file)}
                                      className="img"
                                      alt="post"
                                    />
                                  )}
                                </div>

                                <div className="d-flex align-items-center">
                                  {type === "documents" && (
                                    <button
                                      onClick={(e) => {
                                        handleClikRename(file, index);
                                      }}
                                      type="button"
                                      className="btn btn-primary"
                                    >
                                      Preimenuj
                                    </button>
                                  )}
                                  {file && file.id && type !== "thumb" && (
                                    <div
                                      onClick={() => handleOpenModal(file)}
                                      className="remove-image"
                                    >
                                      <p>X</p>
                                    </div>
                                  )}

                                  {file && !file.id && type !== "thumb" && (
                                    <div
                                      onClick={() => handleOpenModal(index)}
                                      className="remove-image"
                                    >
                                      <p>X</p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </aside>

        {showRenameForm && renameFile && (
          <div className="row ">
            <div className="col-xl-4 col-md-6 col-sm-6">
              <input
                value={fileName}
                onChange={(e: any) => setFileName(e.target.value)}
                className="form-control"
                type="text"
                required={true}
              />
            </div>

            <div className="col col-md-4 col-sm-6 col-xl-4">
              <button
                onClick={handleRename}
                type="button"
                className="btn btn-primary me-3"
              >
                Preimenuj
              </button>

              <button
                onClick={(e) => {
                  setFileName("");
                  setshowRenameForm(false);
                }}
                type="button"
                className="btn btn-secondary"
              >
                X
              </button>
            </div>
          </div>
        )}
      </div>

      {showModalDelete && (
        <Modal onClose={() => setShowModalDelete(false)}>
          <div className="delete-modal">
            <div>
              <h3 className="card-header">Obriši stavku</h3>

              <h2 className="card-body">
                Da li ste sigurni da želite obrisati odabranu stavku?
              </h2>

              <div className="btn-list flex-nowrap justify-content-center">
                <button
                  onClick={() => setShowModalDelete(false)}
                  className="btn btn-primary-outline"
                >
                  Ne
                </button>
                <button
                  type="button"
                  onClick={handleDelete}
                  className="btn btn-danger"
                >
                  Da
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}

export default MyDropzoneComponent;
