import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import MyDropzoneComponent from "src/components/modal/DropZone";
import { Data } from "src/components/shared/models";
import Multiselect from "src/components/shared/multiselect/Multiselect";
import { useAppDispatch, useAppSelector } from "src/store";
import { addDocument, updateDocument } from "src/store/dokument-store";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

interface Props {
  data?: any;
  id?: string;
  is_edit: boolean;
}

function DokumentForm({ data, id, is_edit }: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { solutionTypes: solutionTypesFromState } = useAppSelector(
    (state) => state.solutionTypes
  );

  const { subjectTypes: subjectTypesFromState } = useAppSelector(
    (state) => state.subjectTypes
  );

  const { sPublicProcurements: sPublicProcurementsFromState } = useAppSelector(
    (state) => state.sPublicProcurement
  );

  const { procedureTypes: procedureTypesFromState } = useAppSelector(
    (state) => state.procedureTypes
  );

  const { procurementTypes: procurementTypesFromState } = useAppSelector(
    (state) => state.procurementTypes
  );

  const { CEsuccess, loading } = useAppSelector((state) => state.document);
  const formik = useFormik({
    initialValues: {
      esjn: data ? data.data.esjn_broj : "",
      solutionType:
        data && data.data.solution_type
          ? data.data.solution_type.id
          : solutionTypesFromState &&
            solutionTypesFromState.data &&
            solutionTypesFromState.data.length > 0
          ? solutionTypesFromState.data.find(
              (x) => x.name.cg === "Rješenja komisije"
            )
            ? solutionTypesFromState.data.find((x) =>
                x.name.cg.includes("Rješenja komisije")
              )?.id
            : solutionTypesFromState?.data[0].id
          : "",
      decisionNumber: data ? data.data.decision_number : "",
      appellant: data ? data.data.appellant : "",
      purchaser: data ? data.data.purchaser : "",
      contentCG: data ? data.data.content.cg : "",
      contentEN: data ? data.data.content.en : "",
      dateOfDecision: data ? new Date(data.data.date_of_decision) : new Date(),
      publicationDate: data ? new Date(data.data.publication_date) : new Date(),
      procedureTypeId:
        data && data.data.procedure_type ? data.data.procedure_type.id : "",
      specialPublicProcurementId:
        data && data.data.special_public_procurement
          ? data.data.special_public_procurement.id
          : "",
      procurementType:
        data && data.data.procurement_types
          ? data.data.procurement_types.length > 0 &&
            data.data.procurement_types[0].id
          : "",
    },
    onSubmit: (values: any) => {
      if (
        documentsCG.length === 0 &&
        data.data.documents.filter((x: any) => x.language === "cg").length === 0
      ) {
        setDocumentsCG(null);
        return;
      }
      const formData = new FormData();
      if (values.esjn) {
        formData.append("esjn_broj", values.esjn);
      }

      if (values.contentCG) {
        formData.append("content[cg]", values.contentCG);
      }

      if (values.contentEN) {
        formData.append(
          "content[en]",
          values.contentEN ? values.contentEN : ""
        );
      }

      if (values.decisionNumber) {
        formData.append("decision_number", values.decisionNumber);
      }

      if (values.appellant) {
        formData.append("appellant", values.appellant);
      }

      if (values.purchaser) {
        formData.append("purchaser", values.purchaser);
      }

      if (values.solutionType) {
        formData.append("solution_type_id", values.solutionType);
      }
      if (values.procedureTypeId) {
        formData.append("procedure_type_id", values.procedureTypeId);
      }
      if (values.specialPublicProcurementId) {
        formData.append(
          "special_public_procurement_id",
          values.specialPublicProcurementId
        );
      }

      const dateToSend = moment(values.dateOfDecision).format("YYYY-MM-DD HH:mm:ss");
      formData.append("date_of_decision", dateToSend);

      const dateToSendP = moment(values.publicationDate).format("YYYY-MM-DD HH:mm:ss");
      formData.append("publication_date", dateToSendP);

      subjectTypes.forEach((subtype: string) => {
        formData.append(`subject_types[]`, subtype);
      });

      if (values.procurementType) {
        formData.append("procurement_types[]", values.procurementType);
      }

      solutionOptions.forEach((solutinOption: string) => {
        formData.append("solution_options[]", solutinOption);
      });

      documentsCG !== data?.data.documents &&
        documentsCG.forEach((document: any, index: number) => {
          if (document && !document.id) {
            formData.append(`documents[${index}][path]`, document);
            formData.append(`documents[${index}][lang]`, "cg");
            formData.append(`documents[${index}][order]`, document.order ? document.order : index + 1);

            if (document.display_name) {
              formData.append(
                `documents[${index}][display_name]`,
                document.display_name
              );
            }
          }
        });

      documentsEN !== data?.data.documents &&
        documentsEN.forEach((document: any, index: number) => {
          if (document && !document.id) {
            formData.append(`documents[${index}][path]`, document);
            formData.append(`documents[${index}][lang]`, "en");
            formData.append(`documents[${index}][order]`, document.order ? document.order : index + 1);

            if (document.display_name) {
              formData.append(
                `documents[${index}][display_name]`,
                document.display_name
              );
            }
          }
        });


        decisions !== data?.data.decisions &&
        decisions.forEach((document: any, index: number) => {
          if (document && !document.id) {
            formData.append(`decisions[${index}][path]`, document);
            formData.append(`decisions[${index}][lang]`, "cg");

          }
        });

        lawsuits !== data?.data.lawsuits &&
        lawsuits.forEach((document: any, index: number) => {
          if (document && !document.id) {
            formData.append(`lawsuits[${index}][path]`, document);
            formData.append(`lawsuits[${index}][lang]`, "cg");

          }
        });


      if (is_edit) {
        formData.append("_method", "PUT");
        dispatch(updateDocument({ formData, id }));
      } else {
        dispatch(addDocument(formData));
      }
    },
    validationSchema: Yup.object({
      solutionType: Yup.number().required(),
      decisionNumber: Yup.string().required(),
      contentCG: Yup.string().required(),
      dateOfDecision: Yup.string().required(),
      publicationDate: Yup.string().required(),
      appellant: Yup.string().required(),
      purchaser: Yup.string().required(),
    }),
  });

  const [subjectTypes, setSubjectTypes] = useState(
    data && data.data
      ? data?.data.subject_types.map((type: Data) => {
          return type.id.toString();
        })
      : []
  );

  const [solutionOptions, setSolutionOptions] = useState(
    data && data.data.solution_options ? data.data.solution_options : []
  );

  const [documentsCG, setDocumentsCG] = useState<any>(
    data ? data.data.documents.filter((x: any) => x.language === "cg") : []
  );

  const [documentsEN, setDocumentsEN] = useState<any>(
    data ? data.data.documents.filter((x: any) => x.language === "en") : []
  );

  const [lawsuits, setLawsuits] = useState<any>(
    data && data.data.lawsuits ? data.data.lawsuits: []
  );

  const [decisions, setDecisions] = useState<any>(
    data && data.data.decisions ? data.data.decisions : []
  );

  const handleCheckboxChangeSubjectTypes = (e: any) => {
    const { value, checked } = e.target;

    if (checked) {
      setSubjectTypes([...subjectTypes, value]);
    } else {
      setSubjectTypes(
        subjectTypes.filter((x: any) => x.toString() !== value.toString())
      );
    }
  };

  const checkboxValueCheck = (arrayForCheck: any[], id: number) => {
    return arrayForCheck.find((x) => x === id.toString()) ? true : false;
  };

  useEffect(() => {
    if (CEsuccess) {
      toast.success("Success");
      navigate("/dokumenta");
    }
  }, [CEsuccess, dispatch]);

  return (
    <div className="dodaj-dokument">
      <div className="title-first row g-2 align-items-center">
        <div className="col">
          <h2 className="page-title">Novo rješenje</h2>
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="row row-cards">
          <div className="col-md-6">
            <div className="card">
              <div className="card-body">
                <div className="col-md-12">
                  <h3 className="card-title">
                    Tip rješenja <span className="form-required-filed">*</span>
                  </h3>

                  {solutionTypesFromState &&
                    solutionTypesFromState.data.map((type: Data) => (
                      <label key={type.id} className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          value={type.id}
                          checked={type.id === formik.values.solutionType}
                          onChange={(e) =>
                            formik.setFieldValue("solutionType", type.id)
                          }
                        />
                        <span className="form-check-label">{type.name.cg}</span>
                      </label>
                    ))}

                  {formik.touched.solutionType &&
                    formik.errors.solutionType && (
                      <div className="form-error-message">
                        Tip rješenja je obavezno polje
                      </div>
                    )}
                </div>
                <div className="col-md-12 mt-3">
                  <h3 className="card-title">
                    Broj rješenja <span className="form-required-filed">*</span>
                  </h3>
                  <input
                    className="form-control"
                    type="text"
                    name="decisionNumber"
                    value={formik.values.decisionNumber}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.decisionNumber &&
                    formik.errors.decisionNumber && (
                      <div className="form-error-message">
                        Broj rješenja je obavezno polje
                      </div>
                    )}
                </div>

                <div className="col-md-12 mt-3">
                  <h3 className="card-title">Esjn broj</h3>
                  <input
                    type="text"
                    className="form-control"
                    name="esjn"
                    value={formik.values.esjn}
                    onChange={formik.handleChange}
                  />
                </div>

                <div className="col-md-12 mt-3">
                  <h3 className="card-title">
                    Žalilac <span className="form-required-filed">*</span>{" "}
                  </h3>
                  <input
                    className="form-control"
                    type="text"
                    name="appellant"
                    value={formik.values.appellant}
                    onChange={formik.handleChange}
                  />

                  {formik.touched.appellant && formik.errors.appellant && (
                    <div className="form-error-message">
                      Žalilacje obavezno polje
                    </div>
                  )}
                </div>

                <div className="col-md-12 mt-3">
                  <h3 className="card-title">
                    Naručilac <span className="form-required-filed">*</span>{" "}
                  </h3>
                  <input
                    className="form-control"
                    name="purchaser"
                    type="text"
                    value={formik.values.purchaser}
                    onChange={formik.handleChange}
                  />

                  {formik.touched.purchaser && formik.errors.purchaser && (
                    <div className="form-error-message">
                      Naručilac obavezno polje
                    </div>
                  )}
                </div>

                <div className="col-12 mt-3">
                  <div className="card-header">
                    <ul
                      className="nav nav-tabs card-header-tabs"
                      role="tablist"
                    >
                      <li role="presentation" className="active">
                        <a
                          href="#description-1"
                          className="nav-link active"
                          aria-controls="#description-1"
                          role="tab"
                          data-bs-toggle="tab"
                          aria-selected="true"
                        >
                          CNR
                        </a>
                      </li>
                      <li role="presentation">
                        <a
                          href="#description-2"
                          className="nav-link"
                          aria-controls="#description-2"
                          role="tab"
                          data-bs-toggle="tab"
                          aria-selected="false"
                        >
                          ENG
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="">
                    <div className="tab-content">
                      <div
                        role="tabpanel"
                        className="tab-pane active"
                        id="description-1"
                      >
                        <h3 className="card-title mt-3">
                          Opis predmeta{" "}
                          <span className="form-required-filed">*</span>{" "}
                        </h3>
                        <textarea
                          name="contentCG"
                          value={formik.values.contentCG}
                          onChange={formik.handleChange}
                          className="form-control"
                        ></textarea>
                        {formik.touched.contentCG &&
                          formik.errors.contentCG && (
                            <div className="form-error-message">
                              Opis predmeta je obavezno polje
                            </div>
                          )}
                      </div>
                      <div
                        role="tabpanel"
                        className="tab-pane"
                        id="description-2"
                      >
                        <div className="mb-3">
                          <h3 className="card-title mt-3">
                            Subject description
                          </h3>
                          <textarea
                            name="contentEN"
                            value={formik.values.contentEN}
                            onChange={formik.handleChange}
                            className="form-control"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-header">
                <ul className="nav nav-tabs card-header-tabs" role="tablist">
                  <li role="presentation" className="active">
                    <a
                      href="#documents-1"
                      className="nav-link active"
                      aria-controls="#documents-1"
                      role="tab"
                      data-bs-toggle="tab"
                      aria-selected="true"
                    >
                      CNR
                    </a>
                  </li>
                  <li role="presentation">
                    <a
                      href="#documents-2"
                      className="nav-link"
                      aria-controls="#documents-2"
                      role="tab"
                      data-bs-toggle="tab"
                      aria-selected="false"
                    >
                      ENG
                    </a>
                  </li>
                </ul>
              </div>
              <div className="card-body">
                <div className="tab-content">
                  <div
                    role="tabpanel"
                    className="tab-pane active"
                    id="documents-1"
                  >
                    <h3 className="card-title">
                      Dokumenta <span className="form-required-filed">*</span>{" "}
                    </h3>
                    <MyDropzoneComponent
                      setValue={setDocumentsCG}
                      values={documentsCG}
                      id={id}
                      type="documentss"
                      buttonText="Izaberite dokument"
                    />
                    {!documentsCG && (
                      <div className="form-error-message">Dodajte dokument</div>
                    )}
                  </div>
                  <div role="tabpanel" className="tab-pane" id="documents-2">
                    <h3 className="card-title">Documents</h3>
                    <MyDropzoneComponent
                      setValue={setDocumentsEN}
                      values={documentsEN}
                      id={id}
                      type="documentss"
                      buttonText="Choose document"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-body">
                <div className="col-12">
                  <h3 className="card-title">Odluka upravnog suda</h3>
                  <MyDropzoneComponent
                    setValue={setDecisions}
                    values={decisions}
                    single={false}
                    id={id}
                    type="single"
                    buttonText="Izaberite dokument"
                  />
                </div>

                <div className="col-12 mt-3">
                  <h3 className="card-title">Tužba</h3>
                  <MyDropzoneComponent
                    setValue={setLawsuits}
                    values={lawsuits}
                    id={id}
                    type="single"
                    buttonText="Izaberite dokument"
                    single={false}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card mb-3">
              <div className="card-body">
                <div className="row row-cards">
                  <div className="col-md-12 mt-3">
                    <h3 className="card-title">
                      Datum donosenja rješenja{" "}
                      <span className="form-required-filed">*</span>
                    </h3>
                    <div className="input-icon mb-3">
                      <DatePicker
                        showIcon
                        selected={formik.values.dateOfDecision}
                        onChange={(date: any) =>
                          formik.setFieldValue("dateOfDecision", date)
                        }
                        showTimeInput
                        className="form-control w-100"
                      />
                      {formik.touched.dateOfDecision &&
                        formik.errors.dateOfDecision && (
                          <div className="form-error-message">
                            Datum odluke je obavezno polje
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="col-md-12 mt-3">
                    <h3 className="card-title">
                      Datum objave{" "}
                      <span className="form-required-filed">*</span>
                    </h3>
                    <div className="input-icon mb-3">
                      <DatePicker
                        showIcon
                        selected={formik.values.publicationDate}
                        onChange={(date: any) =>
                          formik.setFieldValue("publicationDate", date)
                        }
                        showTimeInput
                        className="form-control w-100"
                      />
                      {formik.touched.publicationDate &&
                        formik.errors.publicationDate && (
                          <div className="form-error-message">
                            Datum objave je obavezno polje
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="col-md-12 mt-3">
                    <h3 className="card-title">Vrsta predmeta</h3>
                    <div className="mb-3">
                      <div>
                        {subjectTypesFromState &&
                          subjectTypesFromState.data.map((type: Data) => (
                            <label key={type.id} className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={checkboxValueCheck(
                                  subjectTypes,
                                  type.id
                                )}
                                value={type.id}
                                onChange={(e) =>
                                  handleCheckboxChangeSubjectTypes(e)
                                }
                              />
                              <span className="form-check-label">
                                {type.name.cg}
                              </span>
                            </label>
                          ))}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mt-3">
                    <h3 className="card-title">Vrsta postupka</h3>
                    {procedureTypesFromState &&
                      procedureTypesFromState.data.map((type: Data) => (
                        <label key={type.id} className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            value={type.id}
                            checked={type.id === formik.values.procedureTypeId}
                            onChange={(e) =>
                              formik.setFieldValue("procedureTypeId", type.id)
                            }
                          />
                          <span className="form-check-label">
                            {type.name.cg}
                          </span>
                        </label>
                      ))}
                  </div>
                  <div className="col-md-12 mt-3">
                    <h3 className="card-title">Posebni oblici javne nabavke</h3>

                    {sPublicProcurementsFromState &&
                      sPublicProcurementsFromState.data.map((type: Data) => (
                        <label key={type.id} className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            checked={
                              type.id ===
                              formik.values.specialPublicProcurementId
                            }
                            value={type.id}
                            onChange={(e) =>
                              formik.setFieldValue(
                                "specialPublicProcurementId",
                                type.id
                              )
                            }
                          />
                          <span className="form-check-label">
                            {type.name.cg}
                          </span>
                        </label>
                      ))}
                  </div>
                  <div className="col-md-12 mt-3">
                    <div className="mb-3">
                      <h3 className="card-title">
                        Zajedničke i centralizovane nabavke
                      </h3>
                      <div>
                        {procurementTypesFromState &&
                          procurementTypesFromState.data.map((item: any) => (
                            <label key={item.id} className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                checked={
                                  item.id === formik.values.procurementType
                                }
                                value="Zajedničke nabavke"
                                onChange={(e) =>
                                  formik.setFieldValue(
                                    "procurementType",
                                    item.id
                                  )
                                }
                              />
                              <span className="form-check-label">
                                {item.name.cg}
                              </span>
                            </label>
                          ))}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mt-3">
                    <h3 className="card-title">Vrsta rješenja</h3>
                    <div className="mb-3">
                      <Multiselect
                        solutionOptions={solutionOptions}
                        setSolutionOptions={setSolutionOptions}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-end card-footer">
                <button
                  disabled={loading}
                  type="submit"
                  className="btn btn-primary"
                >
                  {loading ? "U obradi..." : "Sačuvaj"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default DokumentForm;