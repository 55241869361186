import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "./interceptors/api";
import { Data, Daum, Name, ReturnDataAdd, Types } from "src/components/shared/models";

interface InitialStateProcedureTypes {
  loading: boolean;
  isSuccess: boolean;
  CEsuccess: boolean;
  CEerror: boolean;
  isError: boolean;
  procedureType: ReturnDataAdd | null;
  procedureTypes: Types | null;
}

const initialProcedureTypesState: InitialStateProcedureTypes = {
  loading: false,
  isSuccess: false,
  isError: false,
  procedureTypes: { links: null, meta: null, data: [] },
  CEsuccess: false,
  CEerror: false,
  procedureType: null
};

export const showProcedureTypes = createAsyncThunk(
  "procedure-types/show",
  async (_, thunkAPI) => {
    try {
      const response = await api.get(`/procedure-types`);
      return response.data as Types;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to fetch issues.");
    }
  }
);


export const addProcedureTypes= createAsyncThunk<ReturnDataAdd, any>(
  "procedure-types/add",
  async (data: Name, thunkAPI) => {
    try {
      const response = await api.post(`/procedure-types`, data);
      return response.data as ReturnDataAdd;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to fetch issues.");
    }
  }
);

export const updateProcedureTypes = createAsyncThunk<ReturnDataAdd, any>(
  "procedure-types/update",
  async (data: Data, thunkAPI) => {
    try {
      const dataToSend = { ...data, _method: "PUT" };

      const response = await api.post(`/procedure-types/${data.id}`, dataToSend);

      return response.data as ReturnDataAdd;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to fetch issues.");
    }
  }
);

export const deleteProcedureTypes = createAsyncThunk<number, any>(
  "procedure-types/delete",
  async (id, thunkAPI) => {
    try {
      const response = await api.delete(`/procedure-types/${id}`);

      return id;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to fetch issues.");
    }
  }
);


const procedureTypesSlice = createSlice({
  name: "procedureTypes",
  initialState: initialProcedureTypesState,
  reducers: {
    resetPocedureTypes(state) {
      state.isSuccess = false;
      state.isError = false;
      state.CEerror = false;
      state.CEsuccess = false;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(showProcedureTypes.pending, (state) => {
        state.loading = true;
      })
      .addCase(showProcedureTypes.fulfilled, (state, action) => {
        state.loading = false;
        state.isSuccess = true;
        state.isError = false;

        state.procedureTypes = action.payload;
      })

      .addCase(showProcedureTypes.rejected, (state) => {
        state.loading = false;
        state.isError = true;
        state.isSuccess = false;
        state.procedureTypes = null;
      })


      .addCase(addProcedureTypes.pending, (state) => {
        state.loading = true;
      })
      .addCase(addProcedureTypes.fulfilled, (state, action) => {
        state.loading = false;
        state.CEsuccess = true;
        state.CEerror = false;

        state.procedureType = action.payload;
        state.procedureTypes = state.procedureTypes &&
          state.procedureTypes.data && {
            ...state.procedureTypes,
            data: [...state.procedureTypes?.data, action.payload.data],
          };
      })

      .addCase(addProcedureTypes.rejected, (state) => {
        state.loading = false;
        state.CEerror = true;
        state.CEsuccess = false;
        state.procedureType = null;
      })


      .addCase(updateProcedureTypes.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateProcedureTypes.fulfilled, (state, action) => {
        state.loading = false;
        state.CEsuccess = true;
        state.CEerror = false;

        state.procedureType = action.payload;

        if (state.procedureType && state.procedureType.data) {
          state.procedureTypes = state.procedureTypes &&
            state.procedureTypes.data && {
              ...state.procedureTypes,
              data: state.procedureTypes?.data.map((type: Daum) => {
                if (type.id === action.payload.data.id) {
                  return action.payload.data;
                }

                return type;
              }),
            };
        }
      })
      .addCase(updateProcedureTypes.rejected, (state) => {
        state.loading = false;
        state.isError = true;
        state.isSuccess = false;
        state.procedureType = null;
      })


      .addCase(deleteProcedureTypes.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteProcedureTypes.fulfilled, (state, action) => {
        state.loading = false;
        state.isSuccess = true;
        state.isError = false;
        state.procedureTypes = state.procedureTypes &&
          state.procedureTypes.data && {
            ...state.procedureTypes,
            data: state.procedureTypes?.data.filter((x) => x.id !== action.payload),
          };
      })
      .addCase(deleteProcedureTypes.rejected, (state) => {
        state.loading = false;
        state.isError = true;
        state.isSuccess = false;
      })
  },
});

export const { resetPocedureTypes } = procedureTypesSlice.actions;
export default procedureTypesSlice.reducer;
