import React from 'react'
import { Categories, Daum } from './models'
import ActionButtons from './ActionButtons'

interface Props{
    lista: Daum[]
    language: string
    editFunction: (data: any) => any
    deleteFunction: (id: Number) => any
    categories?: Categories | null

}

function ListForItem({lista, language, editFunction, deleteFunction, categories} :Props) {
    return (
        <>
        {lista.map((lista: any) => (
          <tr key={lista.id}>
            <td className="text-nowrap text-secondary w-100">{language === "cg" ? lista.name.cg : lista.name.en}</td>
            <td>
              <ActionButtons categories={categories} editFunction={editFunction} deleteFunction={deleteFunction} data={lista} id={lista.id} />
            </td>
          </tr>
        ))}
      </>
      )
}

export default ListForItem